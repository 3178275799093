import actions from "./actions";

const initState = {
    createRechargeReuestLoading: false,
    createRechargeReuestError: null,
    createRechargeReuestMessage: null,
    getRechargeReuestLoading: false,
    getRechargeReuestError: null,
    getRechargeReuestMessage: null,
    getRechargeReuestList: [],
    rechargeStatusModal: false,
    checkRechargStatusLoading: false,
    checkRechargStatusError: null,
    checkRechargStatusMessage: null,
    getRechargeList: [],
    getRechargeLoading: false,
    getRechargeError: null,
    getRechargeMessage: null,
    checkValidMsisdnLoading: false,
    checkValidMsisdnError: null,
    checkValidMsisdnData: [],
    getNetworksForDealerRechargeLoading: false,
    getNetworksForDealerRechargeError: null,
    getNetworksForDealerRechargeMessage: null,
    getNetworksForDealerRechargeList: [],
    createRechargeReuestForAdminLoading: false,
    createRechargeReuestForAdminError: null,
    createRechargeReuestForAdminMessage: null,
};

export default function reducer(state = initState, action) {
    switch (action.type) {

        case actions.GET_NETWORKS_FOR_DEALER_RECHARE:
            return {
                ...state,
                getNetworksForDealerRechargeLoading: true,
                getNetworksForDealerRechargeError: null,
                getNetworksForDealerRechargeMessage: null,
            };
        case actions.GET_NETWORKS_FOR_DEALER_RECHARE_SUCCESS:
            return {
                ...state,
                getNetworksForDealerRechargeLoading: false,
                getNetworksForDealerRechargeError: null,
                getNetworksForDealerRechargeMessage: 'Network get success',
                getNetworksForDealerRechargeList: action.payload.data,
            };
        case actions.GET_NETWORKS_FOR_DEALER_RECHARE_FAILED:
            return {
                ...state,
                getNetworksForDealerRechargeLoading: false,
                getNetworksForDealerRechargeError: action.payload.error,
                getNetworksForDealerRechargeMessage: null,
                getNetworksForDealerRechargeList: [],
            };


        case actions.CHECK_VALID_MSISDN:
            return {
                ...state,
                checkValidMsisdnLoading: true,
                checkValidMsisdnError: null,
                checkValidMsisdnData: [],
                loading: true
            };
        case actions.CHECK_VALID_MSISDN_SUCCESS:
            return {
                ...state,
                checkValidMsisdnLoading: false,
                checkValidMsisdnError: null,
                checkValidMsisdnData: action.payload.data,
                loading: false
            };
        case actions.CHECK_VALID_MSISDN_FAILED:
            return {
                ...state,
                checkValidMsisdnLoading: false,
                checkValidMsisdnError: action.payload.error,
                checkValidMsisdnData: [],
                loading: false,
            };

        case actions.CHECK_RECHARGE_STATUS:

            let tempData = state.getRechargeReuestList.map((item) => {
                let temp = item;
                if (temp.ID == action.payload.data.RechargeID) {
                    temp.rechargeStatusLoading = !temp.rechargeStatusLoading;
                }
                return temp;
            });
            return {
                ...state,
                checkRechargStatusLoading: true,
                checkRechargStatusError: null,
                checkRechargStatusMessage: null,
                getRechargeReuestList: tempData
            };

        case actions.CHECK_RECHARGE_STATUS_SUCCESS:

            state.getRechargeReuestList.map((item) => {
                let temp = item;
                if (temp.ID == action.payload.data.RechargeID) {
                    temp.rechargeStatusLoading = !temp.rechargeStatusLoading;
                }
                return temp;
            });

            return {
                ...state,
                checkRechargStatusLoading: false,
                checkRechargStatusError: null,
                checkRechargStatusMessage: action.payload.message,
                rechargeStatusModal: false,
                // getRechargeReuestList: tempData
            };

        case actions.CHECK_RECHARGE_STATUS_FAILED:
            state.getRechargeReuestList.map((item) => {
                let temp = item;
                if (temp.ID == action.payload.data.RechargeID) {
                    temp.rechargeStatusLoading = !temp.rechargeStatusLoading;
                }
                return temp;
            });

            return {
                ...state,
                checkRechargStatusLoading: false,
                checkRechargStatusError: action.payload.error,
                checkRechargStatusMessage: null,
                rechargeStatusModal: false,
                // getRechargeReuestList: tempData
            };


        case actions.OPEN_RECHARGE_STATUS_MODAL:
            return {
                ...state,
                rechargeStatusModal: true,
            };
        case actions.CLOSE_RECHARGE_STATUS_MODAL:
            return {
                ...state,
                rechargeStatusModal: false,
            };


        case actions.GET_RECHARGE_REQUEST:
            return {
                ...state,
                getRechargeReuestLoading: true,
                getRechargeReuestError: null,
                getRechargeReuestMessage: null,
                getRechargeReuestList: [],
            };
        case actions.GET_RECHARGE_REQUEST_SUCCESS:
            return {
                ...state,
                getRechargeReuestLoading: false,
                getRechargeReuestError: null,
                getRechargeReuestMessage: action.payload.message,
                getRechargeReuestList: action.payload.data,
            };
        case actions.GET_RECHARGE_REQUEST_FAILED:
            return {
                ...state,
                getRechargeReuestLoading: false,
                getRechargeReuestError: action.payload.error,
                getRechargeReuestMessage: null,
                getRechargeReuestList: [],
            };


        case actions.CREATE_RECHARGE_REQUEST:
            return {
                ...state,
                createRechargeReuestLoading: true,
                createRechargeReuestError: null,
                createRechargeReuestMessage: null,
            };
        case actions.CREATE_RECHARGE_REQUEST_SUCCESS:
            return {
                ...state,
                createRechargeReuestLoading: false,
                createRechargeReuestError: null,
                createRechargeReuestMessage: action.payload.message,
            };
        case actions.CREATE_RECHARGE_REQUEST_FAILED:
            return {
                ...state,
                createRechargeReuestLoading: false,
                createRechargeReuestError: action.payload.error,
                createRechargeReuestMessage: null,
            };

        case actions.RESET_RECHARGE_MESSAGES:
            return {
                ...state,
                createRechargeReuestError: null,
                createRechargeReuestMessage: null,
            };

        case actions.GET_RECHARGE:
            return {
                ...state,
                getRechargeList: [],
                getRechargeLoading: true,
                getRechargeError: null,
                getRechargeMessage: null,
            };
        case actions.GET_RECHARGE_SUCCESS:
            return {
                ...state,
                getRechargeLoading: false,
                getRechargeError: null,
                getRechargeMessage: action.payload.message,
                getRechargeList: action.payload.data,
            };
        case actions.GET_RECHARGE_FAILED:
            return {
                ...state,
                getRechargeLoading: false,
                getRechargeError: action.payload.error,
                getRechargeMessage: null,
                getRechargeList: [],
            };

        case actions.CREATE_RECHARGE_REQUEST_FOR_ADMIN:
            return {
                ...state,
                createRechargeReuestForAdminLoading: true,
                createRechargeReuestForAdminError: null,
                createRechargeReuestForAdminMessage: null,
            };
        case actions.CREATE_RECHARGE_REQUEST_FOR_ADMIN_SUCCESS:
            return {
                ...state,
                createRechargeReuestForAdminLoading: false,
                createRechargeReuestForAdminError: null,
                createRechargeReuestForAdminMessage: action.payload.message,
            };
        case actions.CREATE_RECHARGE_REQUEST_FOR_ADMIN_FAILED:
            return {
                ...state,
                createRechargeReuestForAdminLoading: false,
                createRechargeReuestForAdminError: action.payload.error,
                createRechargeReuestForAdminMessage: null,
            };


        default:
            return state;
    }
}