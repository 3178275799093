const actions = {
    RESET_RECHARGE_MESSAGES: 'RESET_RECHARGE_MESSAGES',
    CREATE_RECHARGE_REQUEST: 'CREATE_RECHARGE_REQUEST',
    CREATE_RECHARGE_REQUEST_SUCCESS: 'CREATE_RECHARGE_REQUEST_SUCCESS',
    CREATE_RECHARGE_REQUEST_FAILED: 'CREATE_RECHARGE_REQUEST_FAILED',
    GET_RECHARGE_REQUEST: 'GET_RECHARGE_REQUEST',
    GET_RECHARGE_REQUEST_SUCCESS: 'GET_RECHARGE_REQUEST_SUCCESS',
    GET_RECHARGE_REQUEST_FAILED: 'GET_RECHARGE_REQUEST_FAILED',
    OPEN_RECHARGE_STATUS_MODAL: 'OPEN_RECHARGE_STATUS_MODAL',
    CLOSE_RECHARGE_STATUS_MODAL: 'CLOSE_RECHARGE_STATUS_MODAL',
    CHECK_RECHARGE_STATUS: 'CHECK_RECHARGE_STATUS',
    CHECK_RECHARGE_STATUS_SUCCESS: 'CHECK_RECHARGE_STATUS_SUCCESS',
    CHECK_RECHARGE_STATUS_FAILED: 'CHECK_RECHARGE_STATUS_FAILED',
    GET_RECHARGE: 'GET_RECHARGE',
    GET_RECHARGE_SUCCESS: 'GET_RECHARGE_SUCCESS',
    GET_RECHARGE_FAILED: 'GET_RECHARGE_FAILED',
    CHECK_VALID_MSISDN: "CHECK_VALID_MSISDN",
    CHECK_VALID_MSISDN_SUCCESS: "CHECK_VALID_MSISDN_SUCCESS",
    CHECK_VALID_MSISDN_FAILED: "CHECK_VALID_MSISDN_FAILED",
    GET_NETWORKS_FOR_DEALER_RECHARE: "GET_NETWORKS_FOR_DEALER_RECHARE",
    GET_NETWORKS_FOR_DEALER_RECHARE_SUCCESS: "GET_NETWORKS_FOR_DEALER_RECHARE_SUCCESS",
    GET_NETWORKS_FOR_DEALER_RECHARE_FAILED: "GET_NETWORKS_FOR_DEALER_RECHARE_FAILED",
    CREATE_RECHARGE_REQUEST_FOR_ADMIN: 'CREATE_RECHARGE_REQUEST_FOR_ADMIN',
    CREATE_RECHARGE_REQUEST_FOR_ADMIN_SUCCESS: 'CREATE_RECHARGE_REQUEST_FOR_ADMIN_SUCCESS',
    CREATE_RECHARGE_REQUEST_FOR_ADMIN_FAILED: 'CREATE_RECHARGE_REQUEST_FOR_ADMIN_FAILED',

    getNetworksForDealerRecharge: () => ({
        type: actions.GET_NETWORKS_FOR_DEALER_RECHARE,
    }),
    getNetworksForDealerRechargeSuccess: (data) => ({
        type: actions.GET_NETWORKS_FOR_DEALER_RECHARE_SUCCESS,
        payload: { data }
    }),
    getNetworksForDealerRechargeFailed: (error) => ({
        type: actions.GET_NETWORKS_FOR_DEALER_RECHARE_FAILED,
        payload: { error }
    }),

    checkValidMsisdn: (data) => ({
        type: actions.CHECK_VALID_MSISDN,
        payload: { data }
    }),
    checkValidMsisdnSuccess: (data) => ({
        type: actions.CHECK_VALID_MSISDN_SUCCESS,
        payload: { data }
    }),
    checkValidMsisdnFailed: (error) => ({
        type: actions.CHECK_VALID_MSISDN_FAILED,
        payload: { error }
    }),

    checkRechargeStatus: (data, searchData) => ({
        type: actions.CHECK_RECHARGE_STATUS,
        payload: { data, searchData }
    }),
    checkRechargeStatusSuccess: (message, data) => ({
        type: actions.CHECK_RECHARGE_STATUS_SUCCESS,
        payload: { message, data }
    }),
    checkRechargeStatusFailed: (error, data) => ({
        type: actions.CHECK_RECHARGE_STATUS_FAILED,
        payload: { error, data }
    }),

    closeRechargeStatusModal: () => ({
        type: actions.CLOSE_RECHARGE_STATUS_MODAL,
    }),
    openRechargeStatusModal: () => ({
        type: actions.OPEN_RECHARGE_STATUS_MODAL,
    }),

    getRechargeRequest: (data) => ({
        type: actions.GET_RECHARGE_REQUEST,
        payload: { data }
    }),
    getRechargeRequestSuccess: (message, data) => ({
        type: actions.GET_RECHARGE_REQUEST_SUCCESS,
        payload: { message, data }
    }),
    getRechargeRequestFailed: (error) => ({
        type: actions.GET_RECHARGE_REQUEST_FAILED,
        payload: { error }
    }),

    createRechargeRequest: (data, search) => ({
        type: actions.CREATE_RECHARGE_REQUEST,
        payload: { data, search }
    }),
    createRechargeRequestSuccess: (message) => ({
        type: actions.CREATE_RECHARGE_REQUEST_SUCCESS,
        payload: { message }
    }),
    createRechargeRequestFailed: (error) => ({
        type: actions.CREATE_RECHARGE_REQUEST_FAILED,
        payload: { error }
    }),

    resetRechargeMessage: () => ({
        type: actions.RESET_RECHARGE_MESSAGES,
    }),

    getRecharge: () => ({
        type: actions.GET_RECHARGE,
    }),
    getRechargeSuccess: (data) => ({
        type: actions.GET_RECHARGE_SUCCESS,
        payload: { data }
    }),
    getRechargeFailed: (error) => ({
        type: actions.GET_RECHARGE_FAILED,
        payload: { error }
    }),

    createRechargeRequestForAdmin: (data, search) => ({
        type: actions.CREATE_RECHARGE_REQUEST_FOR_ADMIN,
        payload: { data, search }
    }),
    createRechargeRequestForAdminSuccess: (message) => ({
        type: actions.CREATE_RECHARGE_REQUEST_FOR_ADMIN_SUCCESS,
        payload: { message }
    }),
    createRechargeRequestForAdminFailed: (error) => ({
        type: actions.CREATE_RECHARGE_REQUEST_FOR_ADMIN_FAILED,
        payload: { error }
    }),

}
export default actions;