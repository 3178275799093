import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import moment from "moment";
import EmailInboxAction from './../../../redux/emailInbox/actions';
import AuthAction from './../../../redux/auth/actions';
import noImage from '../../../assets/images/no-data-found.png'
import CardLayout from '../../../components/utils/CardLayout'
import Button from '../../../components/utils/Button';
import InfiniteScroll from 'react-infinite-scroll-component';

const { getMailByMsisdn, readMail, closeMailTemplate, resetTemplateData } = EmailInboxAction
const { getUserDataByToken } = AuthAction;

function EmailInbox() {
    const dispatch = useDispatch();
    const [seachDone, setSeachDone] = useState(false);
    const [openEmailId, setOpenEmailId] = useState('');
    const [searchMobileNumber, setSearchMobileNumber] = useState('');
    const [showNoDataImage, setShowNoDataImage] = useState(false);
    const tokenData = useSelector((state) => state.auth.tokenData);
    const { getMailByMsisdnResult, getMailByMsisdnLoading, showNoDataFlag } = useSelector((state) => state.emailInbox);
    const [searchData, setSearchData] = useState({
        mobileNumber: '',
        dealerID: ''
    });
    const { register, handleSubmit, formState: { errors }, watch, reset: resetForm, control, setValue, } = useForm({
        defaultValues: searchData,
    });

    const [items, setItems] = useState(20);
    const [hasMore, setHasMore] = useState(true);

    const fetchMoreData = () => {
        setTimeout(() => {
            if (items >= getMailByMsisdnResult.length) {
                setHasMore(false);
                return;
            }
            setItems(items + 20);
        }, 1500);
    };


    useEffect(() => {
        dispatch(getUserDataByToken())
    }, [])

    // useEffect(() => {
    //     dispatch(resetTemplateData());
    // }, [getMailByMsisdnResult]);


    const handleChange = (e) => {
        const fname = e.target.name;
        const fvalue = e.target.value;

        setSearchData(prevState => ({
            ...prevState,
            [fname]: fvalue
        }))
    }

    const handleSearchData = (data) => {
        data.dealerID = tokenData && tokenData.length > 0 && tokenData[0].DealerID ? tokenData[0].DealerID : 0;
        setSearchData(prevState => ({
            ...prevState,
            dealerID: data.dealerID
        }))
        setSearchMobileNumber(data.mobileNumber)
        setSeachDone(true);
        dispatch(getMailByMsisdn(data));
        setOpenEmailId('')
        setShowNoDataImage(true)
        setItems(20);
    }

    const handleOpenEmailTepmlate = (mailID) => {
        dispatch(readMail(mailID));
        setOpenEmailId(mailID)
        setShowNoDataImage(false)
    }

    const handleCloseEmaleTemp = (mailID) => {
        dispatch(closeMailTemplate(mailID));
        dispatch(getMailByMsisdn(searchData));
        setOpenEmailId('')
        setShowNoDataImage(false)
    }

    const handleNumber = (e) => {
        const { value } = e.target;
        if (!(e.shiftKey || (e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 8 || e.keyCode === 46)) {
            e.preventDefault();
        }
        if (value.includes('.')) {
            e.preventDefault();
        }
    };


    return (
        <>
            <CardLayout title="E-Mail Inbox" cardClass='main-email-card'>
                <div className='search-email-box iq-card-body'>
                    <form onSubmit={handleSubmit(handleSearchData)}>
                        <div className="form-row mb-3">
                            <div className="col-lg-4">
                                <label>Mobile No</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="mobileNumber"
                                    name="mobileNumber"
                                    placeholder="Mobile No"
                                    {...register("mobileNumber", {
                                        required: 'Mobile No is required!',
                                        pattern: {
                                            value: /^\d{10}$/,
                                            message: "Phone number must be exactly 10 digits"
                                        }
                                    })}
                                    // onWheel={(e) => e.target.blur()}
                                    // onKeyDown={handleNumber} onInput={(e) => {
                                    //     if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength)
                                    // }}
                                    onChange={(e) => handleChange(e)}
                                />
                                {errors && errors.mobileNumber && (<div className="invalid-feedback">{errors.mobileNumber.message}</div>)}
                            </div>
                            <div className='col-lg-6 mt-4 ml-0 pl-0'>
                                <div className="col-md-12" style={{ marginTop: '7px' }}>
                                    <Button title={'Search'} style="minWidth:80px" buttonClass="btn btn-primary" loading={getMailByMsisdnLoading} buttonType='submit' />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                {searchMobileNumber && getMailByMsisdnResult ?
                    <div className='email-inbox-data'>
                        {<div>
                            <h4 className='email-search-msisdn'>{searchMobileNumber}</h4>
                            <hr />
                        </div>}
                        {
                            showNoDataFlag && showNoDataImage && !getMailByMsisdnLoading && seachDone && getMailByMsisdnResult && getMailByMsisdnResult.length === 0 ?
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12'>
                                    <p>No data found</p>
                                        {/* <div className='no-data-img d-flex align-item-center justify-content-center pt-4'>
                                            <img src={noImage} class="img-fluid img-no-dta" alt="..." />
                                        </div> */}
                                    </div>
                                </div> : ''
                        }

                        {getMailByMsisdnResult && getMailByMsisdnResult.length > 0 &&
                            <div>
                                <InfiniteScroll
                                    dataLength={items}
                                    next={fetchMoreData}
                                    hasMore={hasMore}
                                    loader={<div className='d-flex justify-content-center infinite-scroll-loader'><span className="spinner-border spinner-border-sm" role="status"></span></div>}
                                    endMessage={<div className='d-flex justify-content-center mb-0'><p>No more items!</p></div>}
                                >
                                    {
                                        getMailByMsisdnResult && getMailByMsisdnResult.length > 0 && getMailByMsisdnResult.filter((data, ind) => ind <= items && openEmailId ? data.ID == openEmailId : data).map((item, index) => {
                                            let html = JSON.parse(item.ReceivedData).data.html;
                                            return <>

                                                {!item.showMail ? <div className='row' style={{ cursor: 'pointer' }} key={item.ID} onClick={() => handleOpenEmailTepmlate(item.ID)}>
                                                    <div className='col-lg-12 col-md-12' >
                                                        <div className={item.IsRead == 0 ? 'email-card-list read ' : 'email-card-list unread'}>
                                                            <div className='row'>
                                                                <div className='col-lg-3 col-md-3 email-name'><p >{item.ToEmail}</p></div>
                                                                <div className='col-lg-7 col-md-7 second-email text-start'><p>{item.EmailSubject}</p></div>
                                                                <div className='col-lg-2 col-md-2 third-email text-end' style={{ textAlign: 'end' }}><p >{moment(item.CreatedDtTm).format("MMM Do")}</p></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                    : <div className='row '>
                                                        <div className='col-lg-12 col-md-12'>
                                                            <div className='card-email-detail-card'>
                                                                <div className='wrong-icon'>
                                                                    <div className='feb-arrow-icon' style={{ cursor: 'pointer' }} onClick={() => handleCloseEmaleTemp(item.ID)}>
                                                                        <i style={{ fontSize: '1.3rem' }} className="ri-arrow-left-line"></i>
                                                                    </div>
                                                                </div>
                                                                <div className='details-inner-card'>
                                                                    <div dangerouslySetInnerHTML={{ __html: html }} />
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>}
                                            </>
                                        })
                                    }

                                </InfiniteScroll>
                            </div>}
                    </div> : ''}




            </CardLayout>

        </>

    )
}

const mapStateToProps = state => {
    const { getMailByMsisdn, getMailByMsisdnLoading } = state.emailInbox
    return { getMailByMsisdn, getMailByMsisdnLoading };
}

export default EmailInbox;

// export default useHistory(connect(mapStateToProps, {})(EmailInbox));