const actions = {
    GET_ACTIVATION_REQUEST_LIST: "GET_ACTIVATION_REQUEST_LIST",
    GET_ACTIVATION_REQUEST_LIST_SUCCESSFUL: " GET_ACTIVATION_REQUEST_LIST_SUCCESSFUL",
    GET_ACTIVATION_REQUEST_LIST_FAILED: " GET_ACTIVATION_REQUEST_LIST_FAILED",
    PREPARE_ACTIVATION_MODAL: "PREPARE_ACTIVATION_MODAL",
    CLOSE_ACTIVATION_MODAL: "CLOSE_ACTIVATION_MODAL",
    CREATE_ACTIVATION: "CREATE_ACTIVATION",
    CREATE_ACTIVATION_SUCCESSFUL: "CREATE_aCTIVATION_SUCCESSFUL",
    CREATE_ACTIVATION_FAILED: "CREATE_ACTIVATION_FAILED",
    LOCK_ACTIVATION: "LOCK_ACTIVATION",
    LOCK_ACTIVATION_RESULT: "LOCK_ACTIVATION_RESULT",
    LOCK_ACTIVATION_FAILED_RESULT: "LOCK_ACTIVATION_FAILED_RESULT",
    UNLOCK_ACTIVATION: "UNLOCK_ACTIVATION",
    UNLOCK_ACTIVATION_SUCCESS: "UNLOCK_ACTIVATION_SUCCESS",
    PREPARE_COMPLETE_MODAL: "PREPARE_COMPLETE_MODAL",
    CLOSE_COMPLETE_MODAL: "CLOSE_COMPLETE_MODAL",
    PREPARE_CANCEL_MODEL: "PREPARE_CANCEL_MODEL",
    CLOSE_CANCEL_MODAL: "CLOSE_CANCEL_MODAL",
    COMPELETE_ACTIVATION: "COMPELETE_ACTIVATION",
    COMPELETE_ACTIVATION_SUCCESSFULL: "COMPELETE_ACTIVATION_SUCCESSFULL",
    DATA_SUBMIT_FAILED: "DATA_SUBMIT_FAILED",
    ACCESS_TRANSACTION_MODAL: "ACCESS_TRANSACTION_MODAL",
    ACCESS_TRANSACTION_MODAL_SUCCESS: "ACCESS_TRANSACTION_MODAL_SUCCESS",
    RESET_ACTIVATION: "RESET_ACTIVATION",
    PREPARE_CONFIRMATION_MODAL: "PREPARE_CONFIRMATION_MODAL",
    GET_ERROR_CODE: "GET_ERROR_CODE",
    GET_ERROR_CODE_SUCCESS: "GET_ERROR_CODE_SUCCESS",
    GET_ERROR_CODE_FAILED: "GET_ERROR_CODE_FAILED",
    CANCEL_ACTIVATION: "CANCEL_ACTIVATION",
    CANCEL_ACTIVATION_SUCCESSFUL: "CANCEL_aCTIVATION_SUCCESSFUL",
    GET_PLAN_ADDON_DATA: "GET_PLAN_ADDON_DATA",
    GET_PLAN_ADDON_DATA_SUCCESSFUL: " GET_PLAN_ADDON_DATA_SUCCESSFUL",
    GET_PLAN_ADDON_DATA_FAILED: " GET_PLAN_ADDON_DATA_FAILED",
    PREPARE_UPDATE_STATUS_MODEL: "PREPARE_UPDATE_STATUS_MODEL",
    CLOSE_UPDATE_STATUS_MODEL: "CLOSE_UPDATE_STATUS_MODEL",
    UPDATE_ACTIVATION_STATUS: "UPDATE_ACTIVATION_STATUS",
    UPDATE_ACTIVATION_STATUS_SUCCESS: "UPDATE_ACTIVATION_STATUS_SUCCESS",
    UPDATE_ACTIVATION_STATUS_FAILED: "UPDATE_ACTIVATION_STATUS_FAILED",
    VALIDATE_IMEI_NUMBER: "VALIDATE_IMEI_NUMBER",
    VALIDATE_IMEI_NUMBER_SUCCESS: "VALIDATE_IMEI_NUMBER_SUCCESS",
    VALIDATE_IMEI_NUMBER_FAILED: "VALIDATE_IMEI_NUMBER_FAILED",
    RESET_VALIDATE_MESSAGE: "RESET_VALIDATE_MESSAGE",
    OPEN_TRANSACTION_ID_MODAL: "OPEN_TRANSACTION_ID_MODAL",
    CLOSE_TRANSACTION_ID_MODAL: "CLOSE_TRANSACTION_ID_MODAL",

    ADD_BULK_ACTIVATION: "ADD_BULK_ACTIVATION",
    ADD_BULK_ACTIVATION_SUCCESS: "ADD_BULK_ACTIVATION_SUCCESS",
    ADD_BULK_ACTIVATION_FAILED: "ADD_BULK_ACTIVATION_FAILED",

    GET_DEALER_POST_FIX_EMAIL: "GET_DEALER_POST_FIX_EMAIL",
    GET_DEALER_POST_FIX_EMAIL_SUCCESSFUL: " GET_DEALER_POST_FIX_EMAIL_SUCCESSFUL",
    GET_DEALER_POST_FIX_EMAIL_FAILED: " GET_DEALER_POST_FIX_EMAIL_FAILED",

    OPEN_SIM_SWAP_MODAL: " OPEN_SIM_SWAP_MODAL",
    CLOSE_SIM_SWAP_MODAL: " CLOSE_SIM_SWAP_MODAL",
    CREATE_SIM_SWAP_REQUEST: "CREATE_SIM_SWAP_REQUEST",
    CREATE_SIM_SWAP_REQUEST_SUCCESS: "CREATE_SIM_SWAP_REQUEST_SUCCESS",
    CREATE_SIM_SWAP_REQUEST_FAILED: "CREATE_SIM_SWAP_REQUEST_FAILED",

    GET_PREPAID_IQ_ADDRESSES: "GET_PREPAID_IQ_ADDRESSES",
    GET_PREPAID_IQ_ADDRESSES_SUCCESSFUL: " GET_PREPAID_IQ_ADDRESSES_SUCCESSFUL",
    GET_PREPAID_IQ_ADDRESSES_FAILED: " GET_PREPAID_IQ_ADDRESSES_FAILED",

    UPDATE_ACTIVATION_STATUS_IN_BULK: "UPDATE_ACTIVATION_STATUS_IN_BULK",
    UPDATE_ACTIVATION_STATUS_IN_BULK_SUCCESS: "UPDATE_ACTIVATION_STATUS_IN_BULK_SUCCESS",
    UPDATE_ACTIVATION_STATUS_IN_BULK_FAILED: "UPDATE_ACTIVATION_STATUS_IN_BULK_FAILED",

    ADD_MULTI_LINE_BULK_ACTIVATION: "ADD_MULTI_LINE_BULK_ACTIVATION",
    ADD_MULTI_LINE_BULK_ACTIVATION_SUCCESS: "ADD_MULTI_LINE_BULK_ACTIVATION_SUCCESS",
    ADD_MULTI_LINE_BULK_ACTIVATION_FAILED: "ADD_MULTI_LINE_BULK_ACTIVATION_FAILED",

    BACK_CONFIRMATION_MODAL: "BACK_CONFIRMATION_MODAL",

    VALIDATE_SERIAL_NUMBER: "VALIDATE_SERIAL_NUMBER",
    VALIDATE_SERIAL_NUMBER_SUCCESS: "VALIDATE_SERIAL_NUMBER_SUCCESS",
    VALIDATE_SERIAL_NUMBER_FAILED: "VALIDATE_SERIAL_NUMBER_FAILED",

    CHECK_IMEI_NUMBER: "CHECK_IMEI_NUMBER",
    CHECK_IMEI_NUMBER_SUCCESS: "CHECK_IMEI_NUMBER_SUCCESS",
    CHECK_IMEI_NUMBER_FAILED: "CHECK_IMEI_NUMBER_FAILED",

    checkImeiNumber: (data) => ({
        type: actions.CHECK_IMEI_NUMBER,
        payload: { data }
    }),
    checkImeiNumberSuccess: (data) => ({
        type: actions.CHECK_IMEI_NUMBER_SUCCESS,
        payload: { data }
    }),
    checkImeiNumberFailed: (error) => ({
        type: actions.CHECK_IMEI_NUMBER_FAILED,
        payload: { error }
    }),

    validateSerialNumber: (data) => ({
        type: actions.VALIDATE_SERIAL_NUMBER,
        payload: { data }
    }),
    validateSerialNumberSuccess: (data) => ({
        type: actions.VALIDATE_SERIAL_NUMBER_SUCCESS,
        payload: { data }
    }),
    validateSerialNumberFailed: (error) => ({
        type: actions.VALIDATE_SERIAL_NUMBER_FAILED,
        payload: { error }
    }),

    backConfirmationModal: () => ({
        type: actions.BACK_CONFIRMATION_MODAL,
    }),

    addMultiLineBulkActivation: (data, searchData) => ({
        type: actions.ADD_MULTI_LINE_BULK_ACTIVATION,
        payload: { data, searchData }
    }),
    addMultiLineBulkActivationSuccess: (message) => ({
        type: actions.ADD_MULTI_LINE_BULK_ACTIVATION_SUCCESS,
        payload: { message }
    }),
    addMultiLineBulkActivationFailed: (error) => ({
        type: actions.ADD_MULTI_LINE_BULK_ACTIVATION_FAILED,
        payload: { error }
    }),

    updateActivationInBulk: (searchData) => ({
        type: actions.UPDATE_ACTIVATION_STATUS_IN_BULK,
        payload: { searchData }
    }),
    updateActivationInBulkSuccess: (message) => ({
        type: actions.UPDATE_ACTIVATION_STATUS_IN_BULK_SUCCESS,
        payload: { message }
    }),
    updateActivationInBulkFailed: (error) => ({
        type: actions.UPDATE_ACTIVATION_STATUS_IN_BULK_FAILED,
        payload: { error }
    }),

    getPrepaidIqAddresses: (searchData) => ({
        type: actions.GET_PREPAID_IQ_ADDRESSES,
        payload: { searchData }
    }),
    getPrepaidIqAddressesSuccess: (data) => ({
        type: actions.GET_PREPAID_IQ_ADDRESSES_SUCCESSFUL,
        payload: { data }
    }),
    getPrepaidIqAddressesFailed: (error) => ({
        type: actions.GET_PREPAID_IQ_ADDRESSES_FAILED,
        payload: { error }
    }),

    createSimSwapRequest: (data) => ({
        type: actions.CREATE_SIM_SWAP_REQUEST,
        payload: { data }
    }),
    createSimSwapRequestSuccess: (message) => ({
        type: actions.CREATE_SIM_SWAP_REQUEST_SUCCESS,
        payload: { message }
    }),
    createSimSwapRequestFailed: (error) => ({
        type: actions.CREATE_SIM_SWAP_REQUEST_FAILED,
        payload: { error }
    }),

    openSimSwapModal: () => ({
        type: actions.OPEN_SIM_SWAP_MODAL,
    }),
    closeSimSwapModal: () => ({
        type: actions.CLOSE_SIM_SWAP_MODAL,
    }),

    getDealerPostFixEmail: () => ({
        type: actions.GET_DEALER_POST_FIX_EMAIL,
    }),
    getDealerPostFixEmailSuccess: (data) => ({
        type: actions.GET_DEALER_POST_FIX_EMAIL_SUCCESSFUL,
        payload: { data }
    }),
    getDealerPostFixEmailFailed: (error) => ({
        type: actions.GET_DEALER_POST_FIX_EMAIL_FAILED,
        payload: { error }
    }),

    addBulkActivation: (data, searchData) => ({
        type: actions.ADD_BULK_ACTIVATION,
        payload: { data, searchData }
    }),
    addBulkActivationSuccess: (message) => ({
        type: actions.ADD_BULK_ACTIVATION_SUCCESS,
        payload: { message }
    }),
    addBulkActivationFailed: (error) => ({
        type: actions.ADD_BULK_ACTIVATION_FAILED,
        payload: { error }
    }),

    transactionIdModal: () => ({
        type: actions.OPEN_TRANSACTION_ID_MODAL,
    }),
    closeTransactionIdModal: () => ({
        type: actions.CLOSE_TRANSACTION_ID_MODAL
    }),

    getActivationRequestList: (searchData) => ({
        type: actions.GET_ACTIVATION_REQUEST_LIST,
        payload: { searchData }
    }),
    getActivationRequestListSuccessful: (activationRequestResult) => ({
        type: actions.GET_ACTIVATION_REQUEST_LIST_SUCCESSFUL,
        payload: { activationRequestResult }
    }),
    getActivationRequestListFailed: (activationError) => ({
        type: actions.GET_ACTIVATION_REQUEST_LIST_FAILED,
        payload: { activationError }
    }),
    prepareActivationModal: () => ({
        type: actions.PREPARE_ACTIVATION_MODAL,
    }),
    closeActivationModal: () => ({
        type: actions.CLOSE_ACTIVATION_MODAL
    }),
    createActivation: (data, searchData) => ({
        type: actions.CREATE_ACTIVATION,
        payload: { data, searchData }
    }),
    createActivationSuccessful: (activationMessage, activationRequestResult) => ({
        type: actions.CREATE_ACTIVATION_SUCCESSFUL,
        payload: { activationMessage, activationRequestResult }
    }),
    createActivationFailed: (activationError, activationRequestResult) => ({
        type: actions.CREATE_ACTIVATION_FAILED,
        payload: { activationError, activationRequestResult }
    }),
    lockActivation: (lockActivationData, searchData) => ({
        type: actions.LOCK_ACTIVATION,
        payload: { lockActivationData, searchData }
    }),
    lockActivationResultData: (lockData, activationRequestResult) => ({
        type: actions.LOCK_ACTIVATION_RESULT,
        payload: { lockData, activationRequestResult }
    }),
    lockActivationFailedResult: (lockData) => ({
        type: actions.LOCK_ACTIVATION_FAILED_RESULT,
        payload: lockData
    }),
    unLockActivation: (unLockData, searchData) => ({
        type: actions.UNLOCK_ACTIVATION,
        payload: { unLockData, searchData }
    }),
    unLockActivationSuccess: (unLockData, activationRequestResult) => ({
        type: actions.UNLOCK_ACTIVATION_SUCCESS,
        payload: { unLockData, activationRequestResult }
    }),
    prepareCompleteModal: () => ({
        type: actions.PREPARE_COMPLETE_MODAL,
    }),
    closeCompleteModal: () => ({
        type: actions.CLOSE_COMPLETE_MODAL
    }),
    compeleteActivation: (infoData, id, searchData) => ({
        type: actions.COMPELETE_ACTIVATION,
        payload: { infoData, id, searchData }
    }),
    compeleteActivationSuccessfull: (activationRequestResult, message) => ({
        type: actions.COMPELETE_ACTIVATION_SUCCESSFULL,
        payload: { activationRequestResult, message }
    }),
    dataSubmittedFail: (error) => ({
        type: actions.DATA_SUBMIT_FAILED,
        payload: { error }
    }),
    accessTransactionModal: (id) => ({
        type: actions.ACCESS_TRANSACTION_MODAL,
        payload: { id }
    }),
    accessTransactionModalSuccess: (requestData) => ({
        type: actions.ACCESS_TRANSACTION_MODAL_SUCCESS,
        payload: { requestData }
    }),
    resetActivation: () => ({
        type: actions.RESET_ACTIVATION,
    }),
    prepareConfirmationModal: () => ({
        type: actions.PREPARE_CONFIRMATION_MODAL
    }),
    prepareCancelModal: () => ({
        type: actions.PREPARE_CANCEL_MODEL
    }),
    closeCancelModal: () => ({
        type: actions.CLOSE_CANCEL_MODAL
    }),
    getErrorCode: () => ({
        type: actions.GET_ERROR_CODE,
    }),
    getErrorCodeSuccess: (errorCodeResult) => ({
        type: actions.GET_ERROR_CODE_SUCCESS,
        payload: { errorCodeResult }
    }),
    cancelActivation: (cancelData, id, searchData) => ({
        type: actions.CANCEL_ACTIVATION,
        payload: { cancelData, id, searchData }
    }),
    cancelActivationSuccessful: (activationRequestResult, message) => ({
        type: actions.CANCEL_ACTIVATION_SUCCESSFUL,
        payload: { activationRequestResult, message }
    }),

    getPlanAddonData: () => ({
        type: actions.GET_PLAN_ADDON_DATA,
    }),
    getPlanAddonDataSuccessful: (planAddonResult) => ({
        type: actions.GET_PLAN_ADDON_DATA_SUCCESSFUL,
        planAddonResult
    }),
    getPlanAddonDataFailed: (activationError) => ({
        type: actions.GET_PLAN_ADDON_DATA_FAILED,
        payload: { activationError }
    }),
    prepareStatusModel: () => ({
        type: actions.PREPARE_UPDATE_STATUS_MODEL
    }),
    closeStatusModel: () => ({
        type: actions.CLOSE_UPDATE_STATUS_MODEL
    }),
    updateActivationStatus: (updateData, searchData, id) => ({
        type: actions.UPDATE_ACTIVATION_STATUS,
        payload: { updateData, searchData, id }
    }),
    updateActivationStatusSuccess: (message, activationRequestResult, id) => ({
        type: actions.UPDATE_ACTIVATION_STATUS_SUCCESS,
        payload: { message, activationRequestResult, id }
    }),
    updateActivationStatusFailed: (error, activationRequestResult) => ({
        type: actions.UPDATE_ACTIVATION_STATUS_FAILED,
        payload: { error, activationRequestResult }
    }),
    validateImeiNumber: (validateData) => ({
        type: actions.VALIDATE_IMEI_NUMBER,
        payload: { validateData }
    }),
    validateImeiNumberSuccess: (validateSuccess) => ({
        type: actions.VALIDATE_IMEI_NUMBER_SUCCESS,
        payload: { validateSuccess }
    }),
    validateImeiNumberFailed: (validateFailed) => ({
        type: actions.VALIDATE_IMEI_NUMBER_FAILED,
        payload: { validateFailed }
    }),
    resetValidateMessage: () => ({
        type: actions.RESET_VALIDATE_MESSAGE,
    })
};
export default actions;
