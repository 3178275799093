import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData, getList } from '../../helpers/fackBackend_Helper';

function* createRechargeRequestResponse({ payload: { data, search } }) {
    try {
        const response = yield call(addData, '/createRechargeRequest', data);
        if (response.Status) {
            yield put(actions.createRechargeRequestSuccess(response.Message));
            yield put(actions.getRechargeRequest(search));
        } else {
            yield put(actions.createRechargeRequestFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.createRechargeRequestFailed(error.Message));
    }
}

function* getRechargeRequestResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/getRechargeRequestList', data);
        if (response.Status) {
            yield put(actions.getRechargeRequestSuccess(response.Message, response.Data));
        } else {
            yield put(actions.getRechargeRequestFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getRechargeRequestFailed(error.Message));
    }
}

function* checkRechargeStatusResponse({ payload: { data, searchData } }) {
    try {
        const response = yield call(addData, '/updateRechargeStatus', data);
        if (response.Status) {
            yield put(actions.checkRechargeStatusSuccess(response.Message, data));
            yield put(actions.getRechargeRequest(searchData));
        } else {
            yield put(actions.checkRechargeStatusFailed(response.Message, data));
            yield put(actions.getRechargeRequest(searchData));
        }
    } catch (error) {
        yield put(actions.checkRechargeStatusFailed(error.Message, data));
    }
}

function* getRechargeResponse() {
    try {
        const response = yield call(getList, '/getRecharge');
        if (response.Status) {
            yield put(actions.getRechargeSuccess(response.Data));
        } else {
            yield put(actions.getRechargeFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getRechargeFailed(error.Message));
    }
}

function* checkValidMsisdnResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/checkMsisdnEligibility', data);
        if (response.Status) {
            yield put(actions.checkValidMsisdnSuccess(response.response));
        } else {
            yield put(actions.checkValidMsisdnFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.checkValidMsisdnFailed(error.Message));
    }
}

function* getNetworkForDealerRechargeResponse() {
    try {
        const response = yield call(getList, '/getNetworksForDealerRecharge');
        if (response.Status) {
            yield put(actions.getNetworksForDealerRechargeSuccess(response.Data));
        } else {
            yield put(actions.getNetworksForDealerRechargeFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getNetworksForDealerRechargeFailed(error.message));
    }
}

function* createRechargeRequestForAdminResponse({ payload: { data, search } }) {
    try {
        const response = yield call(addData, '/createRechargeRequestForAdmin', data);
        if (response.Status) {
            yield put(actions.createRechargeRequestForAdminSuccess(response.Message));
            yield put(actions.getRechargeRequest(search));
        } else {
            yield put(actions.createRechargeRequestForAdminFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.createRechargeRequestForAdminFailed(error.Message));
    }
}


export default function* rootSaga() {
    yield all([takeEvery(actions.CREATE_RECHARGE_REQUEST, createRechargeRequestResponse)]);
    yield all([takeEvery(actions.GET_RECHARGE_REQUEST, getRechargeRequestResponse)]);
    yield all([takeEvery(actions.CHECK_RECHARGE_STATUS, checkRechargeStatusResponse)]);
    yield all([takeEvery(actions.GET_RECHARGE, getRechargeResponse)]);
    yield all([takeEvery(actions.CHECK_VALID_MSISDN, checkValidMsisdnResponse)]);
    yield all([takeEvery(actions.GET_NETWORKS_FOR_DEALER_RECHARE, getNetworkForDealerRechargeResponse)]);
    yield all([takeEvery(actions.CREATE_RECHARGE_REQUEST_FOR_ADMIN, createRechargeRequestForAdminResponse)]);
}
