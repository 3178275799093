import actions from "./actions";

const initState = {
    activationListLoading: false,
    loading: false,
    buttonLoading: false,
    activationError: null,
    activationMessage: null,
    activationRequestResult: [],
    activationFormModal: false,
    completeModal: false,
    apiErrorModal: null,
    messageModal: null,
    requestData: null,
    confirmationModal: false,
    cancelModal: false,
    errorCodeResult: [],
    Lockmessage: null,
    LockedFailedmessage: null,
    planAddonResult: [],
    updateModal: false,
    validateSuccess: null,
    validateFailed: null,
    transectionIdModal: false,
    addBulkActivationLoading: false,
    addBulkActivationError: null,
    addBulkActivationMessage: null,
    getDealerPostFixEmailData: [],
    getDealerPostFixEmailLoading: false,
    getDealerPostFixEmailError: null,
    getDealerPostFixEmailMessage: null,
    simSwapModal: false,
    createSimSwapRequestLoading: false,
    createSimSwapRequestError: null,
    createSimSwapRequestMessage: null,
    getPrepaidIqAddressesLoading: false,
    getPrepaidIqAddressesError: null,
    getPrepaidIqAddressesData: [],
    updateActivationInBulkLoading: false,
    updateActivationInBulkError: null,
    updateActivationInBulkMessage: null,
    addMultiLineBulkActivationLoading: false,
    addMultiLineBulkActivationError: null,
    addMultiLineBulkActivationMessage: null,
    validateSerialNumberLoading: false,
    validateSerialNumberError: null,
    validateSerialNumberData: [],
    checkImeiNumberLoading: false,
    checkImeiNumberError: null,
    checkImeiNumberData: [],
};

export default function reducer(state = initState, action) {
    let activationListNewState;
    switch (action.type) {

        case actions.CHECK_IMEI_NUMBER:
            return {
                ...state,
                checkImeiNumberLoading: true,
                checkImeiNumberError: null,
                checkImeiNumberData: [],
            };
        case actions.CHECK_IMEI_NUMBER_SUCCESS:
            return {
                ...state,
                checkImeiNumberLoading: false,
                checkImeiNumberError: null,
                checkImeiNumberData: action.payload.data,
            };
        case actions.CHECK_IMEI_NUMBER_FAILED:
            return {
                ...state,
                checkImeiNumberLoading: false,
                checkImeiNumberError: null,
                checkImeiNumberData: [],
            };



        case actions.VALIDATE_SERIAL_NUMBER:
            return {
                ...state,
                validateSerialNumberLoading: true,
                validateSerialNumberError: null,
                validateSerialNumberData: [],
            };
        case actions.VALIDATE_SERIAL_NUMBER_SUCCESS:
            return {
                ...state,
                validateSerialNumberLoading: false,
                validateSerialNumberError: null,
                validateSerialNumberData: action.payload.data,
            };
        case actions.VALIDATE_SERIAL_NUMBER_FAILED:
            return {
                ...state,
                validateSerialNumberLoading: false,
                validateSerialNumberError: null,
                validateSerialNumberData: [],
            };



        case actions.BACK_CONFIRMATION_MODAL:
            return {
                ...state,
                confirmationModal: false,
                activationFormModal: true,
            };

        case actions.ADD_MULTI_LINE_BULK_ACTIVATION:
            return {
                ...state,
                addMultiLineBulkActivationLoading: true,
                addMultiLineBulkActivationError: null,
                addMultiLineBulkActivationMessage: null,
            };
        case actions.ADD_MULTI_LINE_BULK_ACTIVATION_SUCCESS:
            return {
                ...state,
                addMultiLineBulkActivationLoading: false,
                addMultiLineBulkActivationError: null,
                addMultiLineBulkActivationMessage: action.payload.message,
            };
        case actions.ADD_MULTI_LINE_BULK_ACTIVATION_FAILED:
            return {
                ...state,
                addMultiLineBulkActivationLoading: false,
                addMultiLineBulkActivationError: action.payload.error,
                addMultiLineBulkActivationMessage: null,
            };


        case actions.UPDATE_ACTIVATION_STATUS_IN_BULK:
            return {
                ...state,
                updateActivationInBulkLoading: true,
                updateActivationInBulkError: null,
                updateActivationInBulkMessage: null,
            };
        case actions.UPDATE_ACTIVATION_STATUS_IN_BULK_SUCCESS:
            return {
                ...state,
                updateActivationInBulkLoading: false,
                updateActivationInBulkError: null,
                updateActivationInBulkMessage: action.payload.message,
            };
        case actions.UPDATE_ACTIVATION_STATUS_IN_BULK_FAILED:
            return {
                ...state,
                updateActivationInBulkLoading: false,
                updateActivationInBulkError: action.payload.error,
                updateActivationInBulkMessage: null,
            };

        case actions.GET_PREPAID_IQ_ADDRESSES:
            return {
                ...state,
                getPrepaidIqAddressesLoading: false,
                getPrepaidIqAddressesError: null,
                getPrepaidIqAddressesData: [],
            };
        case actions.GET_PREPAID_IQ_ADDRESSES_SUCCESSFUL:
            return {
                ...state,
                getPrepaidIqAddressesLoading: false,
                getPrepaidIqAddressesError: null,
                getPrepaidIqAddressesData: action.payload.data,
            };
        case actions.GET_PREPAID_IQ_ADDRESSES_FAILED:
            return {
                ...state,
                getPrepaidIqAddressesLoading: false,
                getPrepaidIqAddressesError: action.payload.error,
                getPrepaidIqAddressesData: [],
            };

        case actions.CREATE_SIM_SWAP_REQUEST:
            return {
                ...state,
                createSimSwapRequestLoading: true,
                createSimSwapRequestError: null,
                createSimSwapRequestMessage: null,
            };
        case actions.CREATE_SIM_SWAP_REQUEST_SUCCESS:
            return {
                ...state,
                createSimSwapRequestLoading: false,
                createSimSwapRequestError: null,
                createSimSwapRequestMessage: action.payload.message,
                simSwapModal: false,
            };
        case actions.CREATE_SIM_SWAP_REQUEST_FAILED:
            return {
                ...state,
                createSimSwapRequestLoading: false,
                createSimSwapRequestError: action.payload.error,
                createSimSwapRequestMessage: null,
                simSwapModal: false,
            };

        case actions.OPEN_SIM_SWAP_MODAL:
            return {
                ...state,
                simSwapModal: true,
            };
        case actions.CLOSE_SIM_SWAP_MODAL:
            return {
                ...state,
                simSwapModal: false,
            };

        case actions.GET_DEALER_POST_FIX_EMAIL:
            return {
                ...state,
                getDealerPostFixEmailLoading: true,
                getDealerPostFixEmailError: null,
                getDealerPostFixEmailMessage: null,
            };
        case actions.GET_DEALER_POST_FIX_EMAIL_SUCCESSFUL:
            return {
                ...state,
                getDealerPostFixEmailLoading: false,
                getDealerPostFixEmailError: null,
                getDealerPostFixEmailMessage: null,
                getDealerPostFixEmailData: action.payload.data,
            };
        case actions.GET_DEALER_POST_FIX_EMAIL_FAILED:
            return {
                ...state,
                getDealerPostFixEmailLoading: false,
                getDealerPostFixEmailError: action.payload.error,
                getDealerPostFixEmailMessage: null,
            };

        case actions.ADD_BULK_ACTIVATION:
            return {
                ...state,
                addBulkActivationLoading: true,
                addBulkActivationError: null,
                addBulkActivationMessage: null,
            };
        case actions.ADD_BULK_ACTIVATION_SUCCESS:
            return {
                ...state,
                addBulkActivationLoading: false,
                addBulkActivationError: null,
                addBulkActivationMessage: action.payload.message,
            };
        case actions.ADD_BULK_ACTIVATION_FAILED:
            return {
                ...state,
                addBulkActivationLoading: false,
                addBulkActivationError: action.payload.error,
                addBulkActivationMessage: null,
            };

        case actions.OPEN_TRANSACTION_ID_MODAL:
            return {
                ...state,
                transectionIdModal: true,
                activationMessage: null,
                activationError: null,
            }
        case actions.CLOSE_TRANSACTION_ID_MODAL:
            return {
                ...state,
                transectionIdModal: false,
                activationMessage: null,
                activationError: null,
                confirmationModal: false
            }
        case actions.GET_ACTIVATION_REQUEST_LIST:
            return {
                ...state,
                activationListLoading: true,
                activationRequestResult: []
            };
        case actions.GET_ACTIVATION_REQUEST_LIST_SUCCESSFUL:
            return {
                ...state,
                activationListLoading: false,
                activationRequestResult: action.payload.activationRequestResult
            };
        case actions.GET_ACTIVATION_REQUEST_LIST_FAILED:
            return {
                ...state,
                activationError: action.payload.activationError,
                activationListLoading: false,
            };
        case actions.PREPARE_ACTIVATION_MODAL:
            return {
                ...state,
                activationMessage: null,
                activationError: null,
                activationFormModal: true,
                validateSuccess: null,
                validateFailed: null,
            };
        case actions.CLOSE_ACTIVATION_MODAL:
            return {
                ...state,
                activationMessage: null,
                activationError: null,
                activationFormModal: false,
                confirmationModal: false
            };

        case actions.CREATE_ACTIVATION:
            return {
                ...state,
                activationMessage: null,
                activationError: null,
                activationFormModal: false,
                confirmationModal: true,
                buttonLoading: true
            };
        case actions.CREATE_ACTIVATION_SUCCESSFUL:
            return {
                ...state,
                activationMessage: action.payload.activationMessage,
                activationRequestResult: action.payload.activationRequestResult,
                activationError: null,
                activationFormModal: false,
                confirmationModal: true,
                buttonLoading: false,
            };
        case actions.CREATE_ACTIVATION_FAILED:
            return {
                ...state,
                activationMessage: null,
                activationError: action.payload.activationError,
                activationFormModal: false,
                confirmationModal: true,
                buttonLoading: false,
                activationRequestResult: action.payload.activationRequestResult,
            };

        case actions.LOCK_ACTIVATION:
            activationListNewState = state.activationRequestResult.map((item) => {
                let temp = item;
                if (item.ID == action.payload.lockActivationData.activationId) {
                    temp.lockLoading = !temp.lockLoading;
                }
                return temp;
            });

            return {
                ...state,
                activationRequestResult: activationListNewState
            }
        case actions.LOCK_ACTIVATION_RESULT:
            return {
                ...state,
                Lockmessage: action.payload.Message,
                activationRequestResult: action.payload.activationRequestResult,
                locksuccessAlert: true,
                buttonLoading: false
            }
        case actions.LOCK_ACTIVATION_FAILED_RESULT:
            return {
                ...state,
                LockFailAlert: true,
                LockedFailedmessage: action.payload.Message,
                LockedByUser: action.payload.LockedByUser,
                buttonLoading: false
            }
        case actions.UNLOCK_ACTIVATION:
            activationListNewState = state.activationRequestResult.map((item) => {
                let temp = item;
                if (item.ID == action.payload.unLockData.activationId) {
                    temp.releaseLoading = !temp.releaseLoading;
                }
                return temp;
            });
            return {
                ...state,
                activationRequestResult: activationListNewState
            }
        case actions.UNLOCK_ACTIVATION_SUCCESS:
            return {
                ...state,
                unLockMessage: action.payload,
                activationRequestResult: action.payload.activationRequestResult,
                buttonLoading: false
            }
        case actions.PREPARE_COMPLETE_MODAL:
            return {
                ...state,
                completeModal: true,
                activationMessage: null,
                activationError: null,
            }
        case actions.CLOSE_COMPLETE_MODAL:
            return {
                ...state,
                completeModal: false,
                activationMessage: null,
                activationError: null,
                confirmationModal: false
            }
        case actions.COMPELETE_ACTIVATION:
            return {
                ...state,
                buttonLoading: true
            }
        case actions.COMPELETE_ACTIVATION_SUCCESSFULL:
            return {
                ...state,
                messageModal: action.payload.message,
                loading: false,
                apiErrorModal: null,
                activationError: null,
                activationRequestResult: action.payload.activationRequestResult,
                buttonLoading: false
            }
        case actions.DATA_SUBMIT_FAILED:
            return {
                ...state,
                loading: false,
                apiErrorModal: action.payload,
                buttonLoading: false
            }
        case actions.ACCESS_TRANSACTION_MODAL:
            return {
                ...state,
                requestData: null,
                modalViewLoader: true,
                modalFlag: false
            }

        case actions.ACCESS_TRANSACTION_MODAL_SUCCESS:
            return {
                ...state,
                requestData: action.payload.requestData,
                locksuccessAlert: true,
                modalViewLoader: false
            }
        case actions.RESET_ACTIVATION:
            return {
                ...state,
                locksuccessAlert: false,
                modalViewLoader: false,
                modalFlag: false,
                apiErrorModal: null,
                messageModal: null,
                completeModal: false,
                activationError: null,
                activationMessage: null,
                activationFormModal: false,
                confirmationModal: false,
                LockFailAlert: false,
                cancelModal: false,
                updateActivationInBulkError: null,
                updateActivationInBulkMessage: null,
            }
        case actions.PREPARE_CONFIRMATION_MODAL:
            return {
                ...state,
                locksuccessAlert: false,
                modalViewLoader: false,
                modalFlag: false,
                apiErrorModal: null,
                messageModal: null,
                completeModal: false,
                activationError: null,
                activationMessage: null,
                activationFormModal: false,
                confirmationModal: true
            }

        case actions.PREPARE_CANCEL_MODEL:
            return {
                ...state,
                activationMessage: null,
                activationError: null,
                cancelModal: true
            }
        case actions.CLOSE_CANCEL_MODAL:
            return {
                ...state,
                cancelModal: false,
                activationMessage: null,
                activationError: null,
                confirmationModal: false,
                completeModal: false,
            }
        case actions.GET_ERROR_CODE:
            return {
                ...state,
                cancelModal: true,
            }
        case actions.GET_ERROR_CODE_SUCCESS:
            return {
                ...state,
                errorCodeResult: action.payload.errorCodeResult,
                cancelModal: true,
            }
        case actions.CANCEL_ACTIVATION:
            return {
                ...state,
                buttonLoading: true,
                activationMessage: null,
                messageModal: null,
                apiErrorModal: null,
                activationError: null
            }
        case actions.CANCEL_ACTIVATION_SUCCESSFUL:
            return {
                ...state,
                messageModal: action.payload.message,
                loading: false,
                apiErrorModal: null,
                activationError: null,
                activationRequestResult: action.payload.activationRequestResult,
                buttonLoading: false
            }
        case actions.GET_PLAN_ADDON_DATA:
            return {
                ...state,
                loading: false,
            };
        case actions.GET_PLAN_ADDON_DATA_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                planAddonResult: action.planAddonResult
            };
        case actions.GET_PLAN_ADDON_DATA_FAILED:
            return {
                ...state,
                activationError: action.payload.activationError,
                loading: false,
            };
        case actions.PREPARE_UPDATE_STATUS_MODEL:
            return {
                ...state,
                activationError: null,
                activationMessage: null,
                loading: false,
                updateModal: true,
            };
        case actions.CLOSE_UPDATE_STATUS_MODEL:
            return {
                ...state,
                activationError: null,
                activationMessage: null,
                loading: false,
                updateModal: false
            };

        case actions.UPDATE_ACTIVATION_STATUS:

            let tempData = state.activationRequestResult.map((item) => {
                let temp = item;
                if (temp.ID == action.payload.id) {
                    temp.activationUpdateLoading = !temp.activationUpdateLoading;
                }
                return temp;
            });

            return {
                ...state,
                activationError: null,
                activationMessage: null,
                buttonLoading: true,
                activationRequestResult: tempData
                // updateModal: true
            };
        case actions.UPDATE_ACTIVATION_STATUS_SUCCESS:
            state.activationRequestResult.map((item) => {
                let temp = item;
                if (temp.ID == action.payload.id) {
                    temp.activationUpdateLoading = !temp.activationUpdateLoading;
                }
                return temp;
            });

            return {
                ...state,
                activationError: null,
                activationMessage: action.payload.message,
                activationRequestResult: action.payload.activationRequestResult,
                buttonLoading: false,
                updateModal: false
            };
        case actions.UPDATE_ACTIVATION_STATUS_FAILED:
            return {
                ...state,
                activationError: action.payload.error,
                activationMessage: null,
                buttonLoading: false,
                updateModal: false,
                activationRequestResult: action.payload.activationRequestResult,
            };

        case actions.VALIDATE_IMEI_NUMBER:
            return {
                ...state,
                activationError: null,
                activationMessage: null,
            };
        case actions.VALIDATE_IMEI_NUMBER_SUCCESS:
            return {
                ...state,
                activationError: null,
                activationMessage: null,
                validateSuccess: action.payload.validateSuccess,
            };
        case actions.VALIDATE_IMEI_NUMBER_FAILED:
            return {
                ...state,
                activationError: null,
                activationMessage: null,
                validateSuccess: null,
                validateFailed: action.payload.validateFailed
            };

        case actions.RESET_VALIDATE_MESSAGE:
            return {
                ...state,
                activationError: null,
                activationMessage: null,
                validateSuccess: null,
                validateFailed: null,
                validateSuccess: null,
                addBulkActivationMessage: null,
                addBulkActivationError: null,
                addMultiLineBulkActivationError: null,
                addMultiLineBulkActivationMessage: null,
            };

        default:
            return state;
    }
}
