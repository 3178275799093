import { useEffect, useMemo, useState, } from 'react'
import * as XLSX from 'xlsx';
import { useForm } from 'react-hook-form'
import DownloadCloud2sLineIcon from 'remixicon-react/DownloadCloud2LineIcon';
import FolderUploadLineIcon from 'remixicon-react/FolderUploadLineIcon';
import FileSaver from 'file-saver';
import { useDispatch, useSelector } from 'react-redux';
import Papa from 'papaparse'
import Button from '../../components/utils/Button'
import CardLayout from '../../components/utils/CardLayout'
import SearchBar from '../../components/utils/SearchBar'
import PlanWiseMappingActions from '../../redux/planWiseMapping/actions';
import dealerAction from '../../redux/dealer/actions'
import Table from '../../components/utils/Table'
import Alert from '../../components/utils/Alert';
import SideButtons from '../../components/utils/SideButtons'
import { useNavigate } from "react-router-dom";
import networkActions from '../../redux/network/actions'
import { RechargeMapping } from './RechargeMapping';
import { ChildPriceMapping } from './ChildPriceMapping';
import { DailyPlanMapping } from './DailyPlanMapping';

const { getPlanWiseMapping, getAllPlanPriceMappingData, updatePlanPriceMappingData, resetPlanPriceMappingMessages } = PlanWiseMappingActions;
const { getDealer } = dealerAction;

const { getNetwork } = networkActions;

function PlanPriceMapping() {
    const dispatch = useDispatch();
    const [uploadPlanPriceMapping, setUploadPlanPriceMapping] = useState(false);
    const { planWiseMappingList, PlanPriceMappingData, planPriceMappingSuccess, buttonLoading, planPriceMappingError, loading, updateRechargeMappingMessage, updateRechargeMappingError } = useSelector((state) => state.planWiseMapping);
    const { networkResult } = useSelector((state) => state.network);
    const { dealerResult } = useSelector((state) => state.dealer);
    const [previewSubmitBtn, setPreviewSubmitBtn] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');
    const [activeTab, setActiveTab] = useState('Plan Price Mapping');
    const navigate = useNavigate();
    const initialForm = {
        networkID: 1,
        month: 1
    }

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        dispatch(getPlanWiseMapping());
        dispatch(getAllPlanPriceMappingData(initialForm));
        dispatch(getDealer());
        dispatch(getNetwork());
    }, []);

    useEffect(() => {
        if (planPriceMappingError !== null && planPriceMappingError !== undefined) {
            displayAlert(planPriceMappingError, 'alert-danger');
        }
        if (planPriceMappingSuccess !== null && planPriceMappingSuccess !== undefined) {
            displayAlert(planPriceMappingSuccess, 'alert-success');
        }

        if (updateRechargeMappingError !== null && updateRechargeMappingError !== undefined) {
            displayAlert(updateRechargeMappingError, 'alert-danger');
        }
        if (updateRechargeMappingMessage !== null && updateRechargeMappingMessage !== undefined) {
            displayAlert(updateRechargeMappingMessage, 'alert-success');
        }
    }, [planPriceMappingError, planPriceMappingSuccess, updateRechargeMappingMessage, updateRechargeMappingError]);

    /**************** for plan price mapping search  *************************/
    const {
        register: registerPlanPriceMapping,
        handleSubmit: handleSubmitPlanPriceMapping,
        watch: watchPlanPriceMapping,
        formState: {
            errors: errorsPlanPriceMapping
        },
        reset: resetPlanPriceMapping,
        getValues: getValuesPlanMapping
    } = useForm({
        defaultValues: initialForm
    });
    let seachedNetworkId = getValuesPlanMapping('networkID');

    /**************** end for plan price mapping search  *************************/

    const { register, handleSubmit, watch, formState: { errors }, reset, getValues } = useForm({
        defaultValues: {
            networkID: '1',
            month: '1'
        }
    });

    const addSectionNetworkID = watch('networkID');
    const addSectionMonth = watch('month');

    useEffect(() => {
        if (addSectionNetworkID && addSectionMonth) {
            let req = {
                networkID: addSectionNetworkID,
                month: addSectionMonth
            }

            dispatch(getAllPlanPriceMappingData(req));
        }
    }, [addSectionNetworkID, addSectionMonth]);

    const [filterText, setFilterText] = useState("");
    const [uploadedMapping, setUploadedMapping] = useState([]);
    const [previewHeading, setPreviewHeading] = useState([]);
    const [previewDataSourceExcel, setPreviewDataSourceExcel] = useState([]);

    const newDataSource = useMemo(() => {
        if (PlanPriceMappingData && PlanPriceMappingData.length > 0) {
            return Object.values(PlanPriceMappingData && PlanPriceMappingData.length > 0 && PlanPriceMappingData.filter((item) => item.PlanPrice != null && item.PlanPrice != undefined).reduce((acc, item) => {
                const { DealerID, DealerName, DealerPrice, NetworkName, PlanName, PlanPrice, ChildPrice } = item;
                const plannewHeading = `${PlanName}|$${PlanPrice}|${NetworkName}`
                if (!acc[DealerID]) {
                    acc[DealerID] = { DealerID, DealerName, ChildPrice };
                }
                acc[DealerID][plannewHeading] = DealerPrice;
                return acc;
            }, {}));
        } else {
            return [];
        }
    }, [PlanPriceMappingData]);

    const networkData = useMemo(() => {
        if (planWiseMappingList && planWiseMappingList.length > 0) {
            if (!uploadPlanPriceMapping) {
                return planWiseMappingList.filter(item => item.ID == seachedNetworkId).map((item) => {
                    let planData = JSON.parse(item.PlanData);
                    let networkName = item.NetworkName;
                    let newArr = planData.map((plan) => {
                        return `${plan.PlanName}|$${plan.PlanPrice}|${networkName}`;
                    });
                    return newArr;
                })
            } else {
                return planWiseMappingList.filter(item => item.ID == addSectionNetworkID).map((item) => {
                    let planData = JSON.parse(item.PlanData);
                    let networkName = item.NetworkName;
                    let newArr = planData.map((plan) => {
                        return `${plan.PlanName}|$${plan.PlanPrice}|${networkName}`;
                    });
                    return newArr;
                })
            }
        } else {
            return [];
        }
    }, [planWiseMappingList, addSectionNetworkID, PlanPriceMappingData, seachedNetworkId, uploadPlanPriceMapping]);

    const newHeadingData = useMemo(() => {
        if (networkData && networkData.length > 0) {
            return networkData.flat().map(item => ({
                displayName: item,
                key: item
            }));
        } else {
            return [];
        }
    }, [networkData]);

    const heading = [
        { displayName: '#', key: 'key' },
        { displayName: 'Dealer', key: 'dealerName' },
        // { displayName: 'Child Price', key: 'ChildPrice' },
        ...(newHeadingData || [])
    ];

    const Keys = newDataSource && newDataSource.length > 0 && Object.keys(newDataSource[0]);

    const filteredItems = newDataSource && newDataSource.length > 0 && newDataSource.filter(
        item => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
    );

    const DataSource = filteredItems && filteredItems.length > 0 && filteredItems.map((plan, index) => {
        const obj = {
            key: index + 1,
            dealerName: plan.DealerName,
            childPrice: plan.childPrice != '' && plan.childPrice != null && plan.childPrice != undefined ? plan.childPrice : '$0'
        };
        Keys.forEach(key => {
            obj[key] = plan[key] ? `$${plan[key]}` : '$0';
        });
        return obj;
    });

    const handleFilterChange = (value) => {
        setFilterText(value);
    };

    const uploadView = () => {
        setUploadPlanPriceMapping(true);
    }

    const closeUploadView = () => {
        setUploadPlanPriceMapping(false);
        setPreviewSubmitBtn(true)
        setPreviewHeading([])
    }

    const dealerData = dealerResult && dealerResult.length > 0 && dealerResult.filter((item) => item.ParentID != 0).map((item) => {
        return {
            name: item.DealerName,
            dealerId: item.ID
        }
    });

    const exportToCSV = async () => {
        const transformedData = {};
        PlanPriceMappingData.forEach(item => {
            if (!transformedData[item.DealerName]) {
                transformedData[item.DealerName] = {
                    name: item.DealerName,
                    dealerId: item.DealerID,
                    ChildPrice: item.ChildPrice
                };
            }
            transformedData[item.DealerName][`${item.PlanName}|$${item.PlanPrice}|${item.NetworkName}`] = item.DealerPrice;
        });

        const resultArray = Object.values(transformedData);

        const topRowNetworks = networkData.flat(1);

        const wb = XLSX.utils.book_new();
        // const ws = XLSX.utils.aoa_to_sheet([['Dealer Name', 'Dealer ID', 'Month', 'Child Price', ...networkData.flat(1)]]);
        const ws = XLSX.utils.aoa_to_sheet([['Dealer Name', 'Dealer ID', 'Month', ...networkData.flat(1)]]);
        let dealerColIndex = 1;

        if (resultArray && resultArray.length > 0) {
            resultArray && resultArray.length > 0 && resultArray.forEach((planData) => {
                let newRow = [];
                const keys = Object.keys(planData);
                const dealerRow = keys.map(key => planData[key]);
                newRow.push(dealerRow[0]);
                newRow.push(dealerRow[1]);
                newRow.push(addSectionMonth);
                // newRow.push(dealerRow[2] != '' && dealerRow[2] != null && dealerRow[2] != undefined ? dealerRow[2] : '0');

                topRowNetworks.forEach((item) => {
                    if (planData[item]) {
                        newRow.push(planData[item]);
                    } else {
                        newRow.push(0);
                    }
                });

                XLSX.utils.sheet_add_aoa(ws, [newRow], { origin: { r: dealerColIndex, c: 0 } });
                dealerColIndex += 1;
            })
        } else {
            dealerData.forEach((dealer) => {
                const dealerRow = [dealer.name, dealer.dealerId, addSectionMonth];
                XLSX.utils.sheet_add_aoa(ws, [dealerRow], { origin: { r: dealerColIndex, c: 0 } });
                dealerColIndex += 1;
            });

        }

        XLSX.utils.book_append_sheet(wb, ws, 'Plan Price Mapping');
        const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        let fileName = `${addSectionMonth}-month-existing-dealer-plan-price`;
        FileSaver.saveAs(data, `${fileName}.csv`);
    }

    const handleFileChange = (e) => {
        const fileReader = new FileReader();
        let file = e.target.files[0];
        let uploadedData = [];
        setPreviewDataSourceExcel([]);
        if (file) {
            fileReader.onload = async ({ target }) => {
                const csvOutput = Papa.parse(target.result, { header: true });
                const parsedData = csvOutput?.data;
                const columns = Object.keys(parsedData[0]);
                let customizeData = [];

                planWiseMappingList.filter(item => item.ID == addSectionNetworkID).map((item) => {
                    let newArr = JSON.parse(item.PlanData);
                    newArr.map((item1) => {
                        customizeData.push({
                            column: `${item1.PlanName}|$${item1.PlanPrice}|${item.NetworkName}`,
                            planId: item1.ID
                        });
                    });
                });

                dealerData.forEach((dealer) => {
                    customizeData.forEach((item) => {
                        let row = parsedData.filter((item1) => item1['Dealer ID'] == dealer.dealerId);
                        if (row.length > 0) {
                            uploadedData.push({
                                dealerId: dealer.dealerId,
                                dealerName: dealer.name,
                                planId: item.planId,
                                planHeading: item.column,
                                dealerPrice: row[0][item.column],
                                childPrice: row[0]['Child Price'],
                            });
                        } else {
                            console.error(`No data found for dealer with ID ${dealer.dealerId}`);
                        }
                    });
                });

                const uniquePlanIds = [...new Set(uploadedData.map(item => item.planHeading))];
                const heading = [
                    { displayName: '#', key: 'key' },
                    { displayName: 'Dealer', key: 'dealerName' },
                    // { displayName: 'Child Price', key: 'childPrice' },
                    ...uniquePlanIds.map(id => ({ displayName: `${id}`, key: `${id}` }))
                ];

                setPreviewHeading(heading)
                const groupedData = uploadedData.reduce((acc, item) => {
                    const { dealerId, dealerName, planHeading, dealerPrice, childPrice } = item;
                    if (!acc[dealerId]) {
                        acc[dealerId] = { dealerId, dealerName, childPrice };
                    }
                    acc[dealerId][planHeading] = `$${dealerPrice}` || `$0`;
                    return acc;
                }, {});
                setPreviewDataSourceExcel(Object.values(groupedData))
                setUploadedMapping(uploadedData);
                setPreviewSubmitBtn(false)

            };
            fileReader.readAsText(file);
        }
    }

    const previewColumns = previewHeading
    // const previewColumns = previewHeading
    const PreviewKeys = previewDataSourceExcel && previewDataSourceExcel.length > 0 && Object.keys(previewDataSourceExcel[0]);

    const previewDataSource = previewDataSourceExcel && previewDataSourceExcel.length > 0 && previewDataSourceExcel.map((plan, index) => {
        const obj = {
            key: index + 1,
            childPrice: `$${plan.childPrice}`
        };
        PreviewKeys.forEach(key => {
            if (key != 'childPrice') {
                obj[key] = plan[key];
            }
        });
        return obj;
    });

    const onSubmit = (data) => {
        let UpdateDataArray = [];
        let insertedArray = [];
        let selectedMonth = getValues('month');

        uploadedMapping.forEach((firstItem) => {
            let matchFound = false;
            firstItem.dealerPrice = firstItem.dealerPrice >= 0 ? parseFloat(firstItem.dealerPrice) : 0;
            PlanPriceMappingData.forEach((secondItem) => {
                if (firstItem.dealerId === secondItem.DealerID && firstItem.planId === secondItem.PlanID && secondItem.DealerMonth == selectedMonth) {
                    matchFound = true;
                    UpdateDataArray.push(firstItem);
                }
            });
            if (!matchFound) {
                insertedArray.push(firstItem);
            }
        });

        const finalData = {
            insertData: insertedArray,
            updateData: UpdateDataArray,
            month: data.month
        };
        let sData = getValuesPlanMapping();
        // console.log('finalData >>', finalData);
        dispatch(updatePlanPriceMappingData(finalData, sData));
    }

    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
        setUploadPlanPriceMapping(false);
        setPreviewSubmitBtn(true)
        setPreviewHeading([])
        dispatch(resetPlanPriceMappingMessages());
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        dispatch(resetPlanPriceMappingMessages());
    }

    const handleSideButton = () => {
        if (networkResult.length <= 0) {
            navigate("/network");
        } else if (dealerResult.length <= 0) {
            navigate("/dealer");
        }
    }

    const rechargeMappingPage = () => {
        return <RechargeMapping />;
    }
    
    const dailyPlanMappingPage = () => {
        return <DailyPlanMapping />;
    }

    const childPriceMappingPage = () => {
        return <ChildPriceMapping />;
    }

    const handlePlanPriceMappingSearch = (data) => {
        dispatch(getAllPlanPriceMappingData(data));
    }


    return (
        <CardLayout title={activeTab}>
            {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
            <ul className="nav nav-tabs plan-tab" id="myTab" role="tablist" style={{ display: 'flex', gap: '2px' }}>
                <li className="nav-item" role="presentation">
                    <button className={`nav-link ${activeTab === 'Plan Price Mapping' ? 'active' : ''}`} onClick={() => handleTabClick('Plan Price Mapping')} >
                        Plan Price Mapping
                    </button>
                </li>

                <li className="nav-item" role="presentation">
                    <button className={`nav-link ${activeTab === 'Multiline Price Mapping' ? 'active' : ''}`} onClick={() => handleTabClick('Multiline Price Mapping')} >
                        Multiline Price Mapping
                    </button>
                </li>

                <li className="nav-item" role="presentation">
                    <button className={`nav-link ${activeTab === 'Recharge Mapping' ? 'active' : ''}`} onClick={() => handleTabClick('Recharge Mapping')} >
                        Recharge Mapping
                    </button>
                </li>

                <li className="nav-item" role="presentation">
                    <button className={`nav-link ${activeTab === 'Daily Plan Mapping' ? 'active' : ''}`} onClick={() => handleTabClick('Daily Plan Mapping')} >
                        Daily Plan Mapping
                    </button>
                </li>
            </ul>

            {
                activeTab == 'Plan Price Mapping' ?
                    <>
                        {
                            !uploadPlanPriceMapping ? <>
                                <div className='row align-items-center mb-4'>

                                    <div className='col-md-12 mb-2'>
                                        <form onSubmit={handleSubmitPlanPriceMapping(handlePlanPriceMappingSearch)}>
                                            <div className="form-row">

                                                <div className="col-lg-3">
                                                    <label>Select Network</label>
                                                    <select className="form-control" id="networkID" name="networkID" {...registerPlanPriceMapping("networkID", {
                                                    })} >
                                                        {
                                                            networkResult && networkResult.length > 0 && networkResult.filter((item) => item.IsActive == 1).map((item, index) => {
                                                                return <option value={item.ID}>{item.NetworkName}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div className="col-lg-3">
                                                    <label>Select Month</label>
                                                    <select className="form-control" id="month" name="month" {...registerPlanPriceMapping("month", {
                                                    })} >
                                                        <option value="1">1 Month</option>
                                                        <option value="2">2 Month</option>
                                                        <option value="3">3 Month</option>
                                                        <option value="4">4 Month</option>
                                                    </select>
                                                </div>

                                                <div className={`col-lg-2 mt-3`}>
                                                    <div className={`mt-2 d-flex justify-content-end`}>
                                                        <Button title="Search" style="minWidth:80px" loading={loading} buttonClass={`btn btn-primary mt-1 btn-md Activity_searchBtn__GlBtC`} buttonType='submit' />
                                                    </div>
                                                </div>

                                            </div>
                                        </form>
                                    </div>

                                    <div className="col-md-6 od-2">
                                        <SearchBar onFilter={handleFilterChange} filterText={filterText} />
                                    </div>
                                    <div className="col-md-6  text-right">
                                        {
                                            networkResult.length <= 0 ?
                                                <SideButtons title="Add Network" buttonStyle="primary" onClick={handleSideButton} /> :
                                                dealerResult.length <= 0 ?
                                                    <SideButtons title="Add Dealer" buttonStyle="primary" onClick={handleSideButton} /> :
                                                    <Button icon={<FolderUploadLineIcon color='#fff' className='mr-1' size={'0.9rem'} />} title='Upload' buttonClass="btn btn-primary float-right ml-2" onClick={uploadView} loading={false} buttonType='submit' />
                                        }
                                    </div>

                                    <div className="col-md-12 plan-price-mapping">
                                        <Table data={DataSource} columns={heading} loading={loading} pagination={true} tableFixed={true} />
                                    </div>

                                </div>

                            </> :
                                <>
                                    <div className='row align-items-center mb-4'>
                                        <div className="col-md-12 text-right">
                                            <Button icon={<DownloadCloud2sLineIcon color='#fff' className='mr-1' size={'0.9rem'} />} title='Existing Plan Price Mapping' buttonClass="btn btn-primary float-right" onClick={exportToCSV} loading={false} buttonType='submit' />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className='col-md-12'>
                                            <form className="form px-4" onSubmit={handleSubmit(onSubmit)}>

                                                <div className="form-row">

                                                    <div className="col-lg-3">
                                                        <label>Select Network</label>
                                                        <select className="form-control" id="networkID" name="networkID" {...register("networkID", {
                                                        })} >
                                                            {
                                                                networkResult && networkResult.length > 0 && networkResult.filter((item) => item.IsActive == 1).map((item, index) => {
                                                                    return <option value={item.ID}>{item.NetworkName}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>

                                                    <div className="col-lg-3">
                                                        <label>Select Month</label>
                                                        <select className="form-control" id="month" name="month" {...register("month")} >
                                                            <option value="1">1 Month</option>
                                                            <option value="2">2 Month</option>
                                                            <option value="3">3 Month</option>
                                                            <option value="4">4 Month</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="input-group">
                                                            <label htmlFor="csvFile"><span className='text-danger'>*</span>Upload File </label>
                                                            <input
                                                                type="file"
                                                                className="form-control-file"
                                                                name='uploadedFile'
                                                                id="csvFile"
                                                                accept='.csv'
                                                                {...register('uploadedFile', { required: true })}
                                                                onChange={(e) => handleFileChange(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                {previewHeading && previewHeading.length > 0 && <div className='plan-price-mapping'> <Table data={previewDataSource} columns={previewColumns} loading={false} pagination={true} /></div>}

                                                <div className='text-right row mt-2'>
                                                    <div className="col-md-12">
                                                        <button type="button" className="btn btn-secondary mr-2" onClick={closeUploadView}>Close</button>
                                                        <Button title="Submit" disable={previewSubmitBtn} style="minWidth:80px" buttonClass="btn btn-primary" loading={buttonLoading} buttonType='submit' />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                </>
                        }
                    </> : ''
            }

            {
                activeTab == 'Recharge Mapping' ? rechargeMappingPage() : ''
            }

            {
                activeTab == 'Multiline Price Mapping' ? childPriceMappingPage() : ''
            }

            {
                activeTab == 'Daily Plan Mapping' ? dailyPlanMappingPage() : ''
            }


        </CardLayout>
    )
}

export default PlanPriceMapping;