import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData, editData, getList } from '../../helpers/fackBackend_Helper';

function* getActivationRequestResponse({ payload: { searchData } }) {

    try {

        const response = yield call(addData, '/activationRequestList', searchData);
        if (response.Status) {

            yield put(
                actions.getActivationRequestListSuccessful(
                    response.activations
                ));
        } else {

            yield put(actions.getActivationRequestListFailed(response.response.Message));
        }
    } catch (error) {

        yield put(actions.getActivationRequestListFailed(error));
    }
}

function* createActivationRequestResponse({ payload: { data, searchData } }) {
    try {
        const response = yield call(addData, '/createActivationRequest', data);
        if (response.response.Status) {
            const responseList = yield call(addData, '/activationRequestList', searchData);
            yield put(actions.createActivationSuccessful(response.response.Message, responseList.activations));
        } else {
            const responseList = yield call(addData, '/activationRequestList', searchData);
            yield put(actions.createActivationFailed(response.response.Message, responseList.activations));
        }
    } catch (error) {
        yield put(actions.createActivationFailed(error));
    }
}

function* getLockActivation({ payload: { lockActivationData, searchData } }) {
    try {
        const response = yield call(addData, '/lockActivation', lockActivationData);
        if (response.lockData[0].Status) {
            const responseList = yield call(addData, '/activationRequestList', searchData);
            if (responseList.Status) {
                yield put(actions.lockActivationResultData(response.lockData[0].Message, responseList.activations));
            }
        } else {
            yield put(actions.lockActivationFailedResult(response.lockData[0]));
        }
    } catch (error) {
        yield put(actions.getActivationRequestListFailed(error));
    }
}

function* getUnlockActivation({ payload: { unLockData, searchData } }) {
    try {
        const response = yield call(addData, '/releaseLockActivation/', unLockData);
        if (response.lockData[0].Status) {
            const responseList = yield call(addData, '/activationRequestList', searchData);
            if (responseList.Status) {
                yield put(actions.unLockActivationSuccess(response.lockData[0].Message, responseList.activations));
            }
        } else {
            yield put(actions.lockActivationFailedResult(response.lockData[0]));
        }
    } catch (error) {
        yield put(actions.getActivationRequestListFailed(error));
    }
}

function* getCompeleteData({ payload: { infoData, id, searchData } }) {
    try {
        const response = yield call(editData, '/updateRequestForComplete/' + id, infoData);
        if (response.response.Status) {
            const responseList = yield call(addData, '/activationRequestList', searchData);
            if (responseList.Status) {
                yield put(actions.compeleteActivationSuccessfull(responseList.activations, response.response.Message))
            } else {
                yield put(actions.dataSubmittedFail(response.response.Message));
            }
        } else {
            yield put(actions.dataSubmittedFail(response.response.Message));
        }
    } catch (error) {
        yield put(actions.dataSubmittedFail(error));
    }
}

function* getAccessTransactionModal({ payload: { id } }) {
    try {
        const response = yield call(getList, '/activationRequestInfo/' + id);
        if (response.Status) {
            yield put(actions.accessTransactionModalSuccess(response.requestData));
        } else {
            yield put(actions.getActivationRequestListFailed(response));
        }
    } catch (error) {
        yield put(actions.getActivationRequestListFailed(error));
    }
}

function* getErrorCode() {
    try {
        const response = yield call(getList, '/showOnPortalErrorCodes')
        if (response.Status) {
            yield put(actions.getErrorCodeSuccess(response.showOnPortalErrorCodes))
        } else {
            yield put(actions.getActivationRequestListFailed(response.response.Message))
        }
    } catch (error) {
        yield put(actions.getActivationRequestListFailed(error))
    }
}

function* getCancelData({ payload: { cancelData, id, searchData } }) {
    try {
        const response = yield call(editData, '/updateRemark/' + id, cancelData);
        if (response.response.Status) {
            const responseList = yield call(addData, '/activationRequestList', searchData);
            if (responseList.Status) {
                yield put(actions.cancelActivationSuccessful(responseList.activations, response.response.Message))
            } else {
                yield put(actions.dataSubmittedFail(response.response.Message));
            }
        } else {
            yield put(actions.dataSubmittedFail(response.response.Message));
        }
    } catch (error) {
        yield put(actions.dataSubmittedFail(error));
    }
}

function* getPlanAddonDataResponse() {
    try {

        const response = yield call(getList, '/planAddonMapping');
        if (response.response.Status) {

            yield put(
                actions.getPlanAddonDataSuccessful(
                    response.response.Data
                ));
        } else {
            yield put(actions.getPlanAddonDataFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getPlanAddonDataFailed(error));
    }
}

function* updateActivationStatusResponse({ payload: { updateData, searchData, id } }) {
    try {
        const response = yield call(addData, '/updateActivationStatus', updateData);
        if (response.response.Status) {
            const responseList = yield call(addData, '/activationRequestList', searchData);
            yield put(actions.updateActivationStatusSuccess(response.response.Message, responseList.activations, id));
        } else {
            const responseList = yield call(addData, '/activationRequestList', searchData);
            yield put(actions.updateActivationStatusFailed(response.response.Message, responseList.activations));
        }
    } catch (error) {
        yield put(actions.updateActivationStatusFailed(error));
    }
}

function* validateIMEIResponse({ payload: { validateData } }) {
    try {
        const response = yield call(addData, '/validateIMEI', validateData);
        if (response.response.Status) {
            yield put(actions.validateImeiNumberSuccess(response.response.data.ErrorDesc));
        } else {
            yield put(actions.validateImeiNumberFailed(response.response.data.ErrorDesc));
        }
    } catch (error) {
        yield put(actions.validateImeiNumberFailed(error));
    }
}

function* addBulkActivationResponse({ payload: { data, searchData } }) {
    try {
        const response = yield call(addData, '/createBulkActivationRequest', data);
        if (response.Status) {
            yield put(actions.addBulkActivationSuccess(response.Message));
            yield put(actions.getActivationRequestList(searchData));
        } else {
            yield put(actions.addBulkActivationFailed(response.Message));
            yield put(actions.getActivationRequestList(searchData));
        }
    } catch (error) {
        yield put(actions.addBulkActivationFailed(error));
    }
}

function* getDealerPostFixEmailResponse() {
    try {
        const response = yield call(getList, '/getDealerPostFixEmail');
        if (response.Status) {
            yield put(actions.getDealerPostFixEmailSuccess(response.Domains));
        } else {
            yield put(actions.getDealerPostFixEmailFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getDealerPostFixEmailFailed(error));
    }
}

function* createSimSwapRequestResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/createSimSwapRequest', data);
        if (response.Status) {
            yield put(actions.createSimSwapRequestSuccess(response.Message));
        } else {
            yield put(actions.createSimSwapRequestFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.createSimSwapRequestFailed(error));
    }
}

function* getPrepaidIqAddressesResponse({ payload: { searchData } }) {
    try {
        const response = yield call(addData, '/getPrepaidIqAddresses', searchData);
        if (response.Status) {
            yield put(actions.getPrepaidIqAddressesSuccess(response.Data));
        } else {
            yield put(actions.getPrepaidIqAddressesFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getPrepaidIqAddressesFailed(error));
    }
}

function* updateActivationInBulkResponse({ payload: { searchData } }) {
    try {
        const response = yield call(addData, '/update_all_processing_requests', searchData);
        if (response.response.Status) {
            yield put(actions.updateActivationInBulkSuccess(response.response.Message));
            yield put(actions.getActivationRequestList(searchData));
        } else {
            yield put(actions.updateActivationInBulkFailed(response.response.Message));
            yield put(actions.getActivationRequestList(searchData));
        }
    } catch (error) {
        yield put(actions.updateActivationInBulkFailed(error.Message));
        yield put(actions.getActivationRequestList(searchData));
    }
}

function* addMultiLineBulkActivationResponse({ payload: { data, searchData } }) {
    try {
        const response = yield call(addData, '/createMultilineBulkActivationRequest', data);
        if (response.Status) {
            yield put(actions.addMultiLineBulkActivationSuccess(response.Message));
            yield put(actions.getActivationRequestList(searchData));
        } else {
            yield put(actions.addMultiLineBulkActivationFailed(response.Message));
            yield put(actions.getActivationRequestList(searchData));
        }
    } catch (error) {
        yield put(actions.addMultiLineBulkActivationFailed(error));
    }
}

function* validateSerialNumberResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/validateSerialNumber', data);
        if (response.Status) {
            yield put(actions.validateSerialNumberSuccess(response.Data));
        } else {
            yield put(actions.validateSerialNumberFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.validateSerialNumberFailed(error));
    }
}

function* checkImeiNumberResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/validateImeiNumber', data);
        if (response.Status) {
            yield put(actions.checkImeiNumberSuccess(response.Data));
        } else {
            yield put(actions.checkImeiNumberFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.checkImeiNumberFailed(error.message));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.CHECK_IMEI_NUMBER, checkImeiNumberResponse)]);
    yield all([takeEvery(actions.VALIDATE_SERIAL_NUMBER, validateSerialNumberResponse)]);
    yield all([takeEvery(actions.ADD_MULTI_LINE_BULK_ACTIVATION, addMultiLineBulkActivationResponse)]);
    yield all([takeEvery(actions.UPDATE_ACTIVATION_STATUS_IN_BULK, updateActivationInBulkResponse)]);
    yield all([takeEvery(actions.GET_PREPAID_IQ_ADDRESSES, getPrepaidIqAddressesResponse)]);
    yield all([takeEvery(actions.CREATE_SIM_SWAP_REQUEST, createSimSwapRequestResponse)]);
    yield all([takeEvery(actions.GET_DEALER_POST_FIX_EMAIL, getDealerPostFixEmailResponse)]);
    yield all([takeEvery(actions.ADD_BULK_ACTIVATION, addBulkActivationResponse)]);
    yield all([takeEvery(actions.GET_ACTIVATION_REQUEST_LIST, getActivationRequestResponse)]);
    yield all([takeEvery(actions.CREATE_ACTIVATION, createActivationRequestResponse)]);
    yield all([takeEvery(actions.LOCK_ACTIVATION, getLockActivation)]);
    yield all([takeEvery(actions.UNLOCK_ACTIVATION, getUnlockActivation)]);
    yield all([takeEvery(actions.COMPELETE_ACTIVATION, getCompeleteData)]);
    yield all([takeEvery(actions.ACCESS_TRANSACTION_MODAL, getAccessTransactionModal)]);
    yield all([takeEvery(actions.GET_ERROR_CODE, getErrorCode)]);
    yield all([takeEvery(actions.CANCEL_ACTIVATION, getCancelData)]);
    yield all([takeEvery(actions.GET_PLAN_ADDON_DATA, getPlanAddonDataResponse)]);
    yield all([takeEvery(actions.UPDATE_ACTIVATION_STATUS, updateActivationStatusResponse)]);
    yield all([takeEvery(actions.VALIDATE_IMEI_NUMBER, validateIMEIResponse)]);
}