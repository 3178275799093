import React, { useEffect, useState } from 'react'

import Modal from '../../../components/utils/Model';
import Button from '../../../components/utils/Button';
import { useForm } from 'react-hook-form';
import SimSwapActions from '../../../redux/simSwapRequest/actions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import AuthAction from './../../../redux/auth/actions';
import Alert from '../../../components/utils/Alert';



const { getSimSwapRequest, closeSimSwapFormModal, getActivationDetailsByMsisdn, createSIMSwapRequest, resetMessage } = SimSwapActions;
const { getUserDataByToken } = AuthAction;

const TIMEZONE = process.env.REACT_APP_TIMEZONE;



const SimSwapFormModal = () => {
    const dispatch = useDispatch();
    const { simSwapFormModal, activationDetailsList, activationDetailsLoading, activationDetailsError, createSimSwapRequestMessage, createSimSwapRequestLoading } = useSelector((state) => state.simSwapRequest);
    const tokenData = useSelector((state) => state.auth.tokenData);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');

    const [swapSearchData, setSwapSearchData] = useState({
        mobileNumber: '',
        dealerId: '',
        newSIMNumber: '',
        swapDate: moment(new Date()).tz(TIMEZONE).format("YYYY-MM-DD"),
    });

    const { register, handleSubmit, formState: { errors }, watch, reset, control } = useForm({
        defaultValues: swapSearchData,
    });
    const { register: registerNewSIMNo, handleSubmit: handleSubmitNewSIMNo, formState: { errors: newSIMNoError }, watch: watchNewSIMNo, reset: resetNewSIMNo, setValue } = useForm({ defaultValues: swapSearchData });

    useEffect(() => {
        dispatch(getUserDataByToken())
    }, [])

    useEffect(() => {
        if (createSimSwapRequestMessage) {
            reset()
            resetNewSIMNo()
        }

    }, [createSimSwapRequestMessage])

    useEffect(() => {
        if (activationDetailsError !== null && activationDetailsError !== undefined) {
            displayAlert(activationDetailsError, 'alert-danger');
        }
    }, [activationDetailsError]);


    const onSubmit = (data) => {
        resetNewSIMNo()
        setSwapSearchData(pre => ({
            mobileNumber: data.mobileNumber
        }))
        data.dealerId = tokenData && tokenData.length > 0 && tokenData[0].DealerID ? tokenData[0].DealerID : 0;
        dispatch(getActivationDetailsByMsisdn(data))
    }
    const handleCancel = () => {
        dispatch(closeSimSwapFormModal())
        reset()
        resetNewSIMNo()
        alertCancel()
    }

    const handleAddNewSIMNumber = (data) => {
        activationDetailsList[0].NewSIMNumber = data.newSIMNumber
        data.mobileNumber = swapSearchData.mobileNumber
        delete data.dealerId;
        delete data.swapDate;
        const newData = {
            ActivationID: activationDetailsList[0].ID,
            NewSIMNumber: data.newSIMNumber,
            MSISDN: swapSearchData.mobileNumber,
            ChildActivationID: activationDetailsList[0].ChildActivationID ? activationDetailsList[0].ChildActivationID : 0,
            SerialNumber: activationDetailsList[0].SerialNumber,
            RequestType: activationDetailsList[0].RequestType,
            CompanyID: activationDetailsList[0].CompanyID,
            SimTypeID: activationDetailsList[0].SimTypeID,
            NetworkID: activationDetailsList[0].NetworkID,
            CustomerEmail: activationDetailsList[0].CustomerEmail,
            TransactionID: activationDetailsList[0].TransactionID
        }
        dispatch(createSIMSwapRequest(newData))
    }

    const requestInformationData = activationDetailsList && activationDetailsList.length > 0 ? [
        {
            name: "IMEI",
            value: activationDetailsList[0].IMEI,
            div: 'col-lg-6 d-flex mb-2'
        },
        {
            name: "SIM Type",
            value: activationDetailsList[0].SimType,
            div: 'col-lg-6 d-flex mb-2'
        },
        {
            name: "Serial #",
            value: activationDetailsList[0].SerialNumber,
            div: 'col-lg-6 d-flex mb-2'
        },
        {
            name: "Mobile #",
            value: activationDetailsList[0].MSISDN,
            div: 'col-lg-6 d-flex mb-2'
        },
        {
            name: "Activation Type",
            value: activationDetailsList[0].ActivationType == 7 ? 'Normal' : 'Port In',
            div: 'col-lg-6 d-flex mb-2'
        },
        {
            name: "Account #",
            value: activationDetailsList[0].AccountNumber,
            div: 'col-lg-6 d-flex mb-2'
        },
        {
            name: "Dealer Name",
            value: activationDetailsList[0].DealerName,
            div: 'col-lg-6 d-flex mb-2'
        },
        {
            name: "WiFi Calling",
            value: activationDetailsList[0].WiFiCalling ? 'Yes' : 'No',
            div: 'col-lg-6 d-flex mb-2'
        },
        {
            name: "Network",
            value: activationDetailsList[0].NetworkName,
            div: 'col-lg-6 d-flex mb-2'
        },
        {
            name: "Customer Email",
            value: activationDetailsList[0].CustomerEmail,
            div: 'col-lg-8 d-flex mb-2'
        },

    ] : [];

    const planData = activationDetailsList && activationDetailsList.length > 0 ? [
        {
            name: "Plan Category",
            value: activationDetailsList[0].PlanCategory,
        },
        {
            name: "Plan Name",
            value: activationDetailsList[0].PlanName,
        },
        {
            name: "Plan Price",
            value: '$' + activationDetailsList[0].PlanPrice,
        },
        {
            name: "Plan Month",
            value: activationDetailsList[0].PlanMonth + ' Month(s)',
        },
    ] : [];

    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
        dispatch(resetMessage())
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        dispatch(resetMessage())
    }

    const handleNumber = (e) => {
        const { value } = e.target;
        if (!(e.shiftKey || (e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 8 || e.keyCode === 46)) {
            e.preventDefault();
        }
        if (value.includes('.')) {
            e.preventDefault();
        }
    };

    return (
        <>
            <Modal
                showModal={simSwapFormModal}
                title={'SIM Swap'}
                onHide={handleCancel}
                width={700}
                showFooter={false}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-row mb-3">
                        <div className="col-lg-6">
                            <label>MSISDN</label>
                            <input
                                type="text"
                                maxLength={10}
                                className="form-control"
                                id="msisdn"
                                name="mobileNumber"
                                placeholder="MSISDN"
                                {...register("mobileNumber", {
                                    required: 'MSISDN is required!',
                                    pattern: {
                                        value: /^\d{10}$/,
                                        message: "Phone number must be exactly 10 digits"
                                    }
                                })}
                            // onWheel={(e) => e.target.blur()}
                            // onKeyDown={handleNumber} onInput={(e) => {
                            //     if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength)
                            // }}
                            />
                            {errors && errors.mobileNumber && (<div className="invalid-feedback">{errors.mobileNumber.message}</div>)}

                        </div>
                        <div className='col-lg-6 row mt-4'>
                            <div className="col-md-12" style={{ marginTop: '7px' }}>
                                <Button title={'Fetch'} style="minWidth:80px" buttonClass="btn btn-primary" loading={activationDetailsLoading} buttonType='submit' />
                                <button type="button" className="btn btn-secondary ml-2" onClick={handleCancel}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </form>
                {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}

                <form onSubmit={handleSubmitNewSIMNo(handleAddNewSIMNumber)}>
                    {activationDetailsList && activationDetailsList.length > 0 ? <div className="form-row ">
                        <div className="col-lg-12">
                            <div className='request-info-sim'>
                                <h5 className='mb-2' style={{ fontWeight: '600' }}>REQUEST INFORMATION</h5>
                                <hr />
                                <div className='row'>
                                    {requestInformationData && requestInformationData.length > 0 && requestInformationData.map((item, ind) => (
                                        <div className={item.div}>
                                            <div className={'col-lg-6 sim-swap-heading'}>{item.name}</div>
                                            <div className={'col-lg-6 p-0'} style={{ textAlign: 'end' }}>{item.value}</div>
                                        </div>
                                    ))}
                                </div>

                                <h5 className='mb-2' style={{ fontWeight: '600' }}>PLAN INFORMATION</h5>
                                <hr />
                                <div className='row'>{planData && planData.length > 0 && planData.map((item, ind) => (
                                    <div className={ind > 1 ? 'col-lg-6 d-flex mb-2' : 'col-lg-12 d-flex mb-2'}>
                                        <div className={ind > 1 ? 'col-lg-6 sim-swap-heading' : 'col-lg-4 sim-swap-heading'}>{item.name}</div>
                                        <div className={ind > 1 ? 'col-lg-6 p-0' : 'col-lg-8 p-0'} style={ind > 1 ? { textAlign: 'end' } : { textAlign: 'start' }}>{item.value}</div>
                                    </div>
                                ))}</div>
                                <hr />

                                {
                                    activationDetailsList[0].NetworkID != 1 ? <>
                                        <div className='container'>
                                        <h5 className='text-danger'>A SIM swap is only possible when activating with T-Mobile.</h5>
                                        </div>
                                    </> :
                                        <>
                                            <div className="form-row mb-2">
                                                <div className="col-lg-6">
                                                    <label>New SIM Number</label>
                                                    <input type="number" minLength={19} className="form-control" id="newSIMNumber" name="newSIMNumber" placeholder="New SIM Number" {...registerNewSIMNo("newSIMNumber",
                                                        {
                                                            required: 'New SIM number is required!',
                                                            minLength: {
                                                                value: 19,
                                                                message: "New SIM number should contain 19 digits"
                                                            },
                                                            maxLength: {
                                                                value: 19,
                                                                message: "New SIM number should contain 19 digits"
                                                            }
                                                        }
                                                    )} />
                                                    {newSIMNoError && newSIMNoError.newSIMNumber && (<div className="invalid-feedback">{newSIMNoError.newSIMNumber.message}</div>)}
                                                </div>
                                                <div className='col-lg-6 mt-4'>
                                                    <div className="col-md-12" style={{ marginTop: '7px' }}>
                                                        <Button title={'Add For SIM Swap'} style="minWidth:80px" buttonClass="btn btn-primary" loading={createSimSwapRequestLoading} buttonType='submit' />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                }


                            </div>

                        </div>

                    </div> : ''}
                </form>
            </Modal>
        </>
    )
}

export default SimSwapFormModal;